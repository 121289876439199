<template>
  <div class="DialogReportPaid__Component">
    <form @submit.prevent="save" novalidate="true">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">הוספת פרופיל</p>
          <button type="button" class="delete" @click="$emit('close')" />
        </header>
        <section class="modal-card-body">
          <div class="DialogReportPaid__Component__Loading">
            <div class="field">
              <b-field label="שם">
                <b-input type="text" v-model="$v.model.Name.$model"> </b-input>
              </b-field>
              <div>
                <span
                  v-if="!$v.model.Name.required && $v.model.Name.$error"
                  class="error"
                  >שדה חובה</span
                >
              </div>
            </div>
            <div class="field">
              <b-field label="ח.פ">
                <b-input type="text" v-model="$v.model.IdNumber.$model">
                </b-input>
              </b-field>
              <div>
                <span
                  v-if="!$v.model.IdNumber.required && $v.model.IdNumber.$error"
                  class="error"
                  >שדה חובה</span
                >
              </div>
            </div>
            <div class="field">
              <b-field label="מייל">
                  <b-input type="text" v-model="$v.model.Email.$model">
                  </b-input>
              </b-field>
              <div>
                <span class="error" v-if="$v.model.Email.$error">אנא הזן מייל תקין</span>
              </div>
            </div>
            <div v-if="model.IsForeignCitizen" class="field">
              <b-field label="עיר">
                <b-input type="text" v-model="model.City"> </b-input>
              </b-field>
            </div>
            <div v-if="model.IsForeignCitizen" class="field">
              <b-field label="רחוב">
                <b-input type="text" v-model="model.Street"> </b-input>
              </b-field>
            </div>
            <FieldInlineSelect v-if="!model.IsForeignCitizen" :alert-not-exist="true" :hide-hover="true" label="עיר" :clearable="true" :filterable="true"
              :null-is-value="true" placeholder="הקלד עיר" :options="[]" :fetch-options="onSearchCities"
              @onChange="onChange" :reduce="(item) => item.Text" v-model="model.City" />

            <FieldInlineSelect v-if="!model.IsForeignCitizen" :readOnly="!model.City" :alert-not-exist="true" :hide-hover="true" label="רחוב" :clearable="true" :filterable="true"
              :null-is-value="true" placeholder="הקלד רחוב" :options="[]" :fetch-options="onSearchCityAddress"
              @onChange="onChange" :reduce="(item) => item" v-model="model.Street" />
            <div class="field">
              <b-field label="מספר בית">
                <b-input type="text" v-model="model.HouseNumber"> </b-input>
              </b-field>
            </div>
            <div class="field">
              <b-field label="מיקוד">
                <b-input type="text" v-model="model.ZipCode"> </b-input>
              </b-field>
            </div>
            <div class="field flex">
              <b-field class="flex-1" label="חברת ליסינג">
                <b-switch class="ltr" v-model="model.IsLeasingCompany">{{
                  model.IsLeasingCompany ? "כן" : "לא"
                }}</b-switch>
              </b-field>
              <b-field class="flex-1" label="תייר">
                <b-switch class="ltr" v-model="model.IsForeignCitizen">{{
                  model.IsForeignCitizen ? "כן" : "לא"
                }}</b-switch>
              </b-field>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <b-button
            native-type="submit"
            :loading="isLoading"
            label="שמור"
            type="is-primary"
          />
          <b-button
            :disabled="isLoading"
            label="סגור"
            @click="$emit('close')"
          />
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { ToastProgrammatic as Toast } from "buefy";
import ProfileService from "@/services/ProfileService";
import FieldInlineSelect from "@/components/Fields/FieldInlineSelect.vue";
import { required, email } from "vuelidate/lib/validators";
import DriverService from "../services/DriverService";

export default {
  name: "DialogProfileUserAdd",
  components: {
    FieldInlineSelect
  },
  props: ["onSuccess"],
  data() {
    return {
      isLoading: false,
      model: {
        Name: null,
        IsLeasingCompany: false,
        IsForeignCitizen: false,
        IdNumber: null,
        Email: null,
        Street: null,
        HouseNumber: null,
        City: null,
        ZipCode: null,
      },
      errors: {},
    };
  },
  validations: {
    model: {
      Name: { required },
      IdNumber: { required },
      Email: { email }
    },
  },
  methods: {
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.isLoading = true;
      const data = { ...this.model };
      data.Street = this.model.Street?.Text;
      ProfileService.addProfile(data)
        .then(() => {
          this.$emit("close");
          this.onSuccess();
          Toast.open({
            type: "is-success",
            message: "הפעולה בוצעה!",
            duration: 4000,
          });
        })
        .catch((e) => {
          let message = '';
          switch (e.response.data.ErrorCode) {
            case 11:
              message = "קיים כבר משתמש כזה במערכת.";
              break;
            default:
              message = "לא ניתן לבצע את הפעולה!";
              break;
          }
          Toast.open({
            type: "is-danger",
            message,
            duration: 8000,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onChange() {},
    onSearchCities({ search, loading, setData, setValue }) {
      if (search.length >= 3) {
        loading(true);
        return (
          DriverService.searchCity(1, 1000, search)
        )
          .then((r) => {
            if (!r.data.Items.length) {
              setValue({ Value: -1, Text: search });
            } else {
              setData(r.data.Items.map((i) => ({ Value: i.Id, Text: i.Name })));
              this.model.Street = null;
            }
          })
          .finally(() => {
            loading(false);
          });
      }

      return null;
    },
    onSearchCityAddress({ search, loading, setData, setValue }) {
      if (search.length >= 3) {
        loading(true);
        return (
          DriverService.searchCityAddress(this.model.City, 1, 1000, search)
        )
          .then((r) => {
            if (!r.data.Items.length) {
              setValue({ Value: -1, Text: search });
            } else {
              setData(r.data.Items.map((i) => ({ Value: i.Id, Text: i.Name })));
            }
          })
          .finally(() => {
            loading(false);
          });
      }

      return null;
    },
  },
};
</script>

<style lang="scss">
.error {
  color: red;
  font-size: 15px;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}
</style>
