<template>
  <div class="Profile__Page">
    <div class="Profile__Page__Menu">
      <h1>אדמיניסטרציה</h1>
      <b-menu>
        <b-menu-list>
          <b-menu-item label="פרופילים" tag="router-link" to="/admin/profiles"></b-menu-item>
          <b-menu-item label="ניהול דוחות" tag="router-link" to="/admin/search/reports"></b-menu-item>
          <b-menu-item label="דוחות לא מנוהלים" tag="router-link" to="/admin/search/unmanagedreports"></b-menu-item>
          <b-menu-item label="ניהול רכבים" tag="router-link" to="/admin/search/vehicles"></b-menu-item>
          <b-menu-item label="ניהול עיריות" tag="router-link" to="/admin/search/municipalities"></b-menu-item>
          <b-menu-item>
            <template #label="props">
              ייבוא
              <b-icon class="is-pulled-left" :icon="props.expanded ? 'menu-up' : 'menu-down'"></b-icon>
            </template>
            <b-menu-item label="דוחות" tag="router-link" to="/admin/import/reports"></b-menu-item>
            <b-menu-item label="נהגים" tag="router-link" to="/admin/import/drivers"></b-menu-item>
            <b-menu-item label="רכבים" tag="router-link" to="/admin/import/vehicles"></b-menu-item>
            <b-menu-item label="רכבים עם בעלות בלבד" tag="router-link" to="/admin/import/vehicles/owned"></b-menu-item>
            <b-menu-item label="רכבים גרסה 2" tag="router-link" to="/admin/import/newvehicles"></b-menu-item>
            <b-menu-item label="משטרה" tag="router-link" to="/admin/import/police"></b-menu-item>
            <!-- <b-menu-item label="חוזה נהגים" tag="router-link" to="/admin/import/driversContract"></b-menu-item> -->
          </b-menu-item>
          <!-- <b-menu-item>
            <template #label="props">
              ניתוח פעילות
              <b-icon class="is-pulled-left" :icon="props.expanded ? 'menu-up' : 'menu-down'"></b-icon>
            </template>
            <b-menu-item label="דו''ח שנתי" tag="router-link" to="/admin/analytics/yearly"></b-menu-item>
          </b-menu-item> -->
          <b-menu-item>
            <template #label="props">
              כלים
              <b-icon class="is-pulled-left" :icon="props.expanded ? 'menu-up' : 'menu-down'"></b-icon>
            </template>
            <b-menu-item label="יצירת קובץ לייבוא" tag="router-link" to="/admin/import/create"></b-menu-item>
            <b-menu-item label="סריקת נתוני מיקום" tag="router-link" to="/admin/import/locationdatascan"></b-menu-item>
            <b-menu-item label="סריקת נתוני דוחות" tag="router-link" to="/admin/crawlReports"></b-menu-item>
            <b-menu-item label="מחולל התראות" tag="router-link" to="/admin/import/generatealerts"></b-menu-item>
          </b-menu-item>
          <b-menu-item>
            <template #label="props">
              שידור דוחות
              <b-icon class="is-pulled-left" :icon="props.expanded ? 'menu-up' : 'menu-down'"></b-icon>
              <b-icon size="is-small" class="is-pulled-left" :class="{
                'is-success': isHealthy,
                'is-danger': isHealthy === false,
              }" icon="circle"></b-icon>
            </template>
            <b-menu-item>
              <template #label>
                <div class="is-flex is-justify-content-space-evenly is-flex-direction-column">
                  <div class="field">
                    <b-field label="בחר הרצה">
                      <v-select dir="rtl" label="Text" v-model="reportsType" :options="reportsOptions" :searchable="false"
                        :clearable="false" :close-on-select="true" :placeholder="'בחר ערך מהרשימה'">
                        <div slot="no-options">לא נמצאו ערכים דומים</div>
                      </v-select>
                    </b-field>
                  </div>
                  <b-button @click.prevent="startTransmittion">הפעל</b-button>
                  <b-button @click.prevent="cancelTransmittion">הפסק</b-button>
                </div>
              </template>
            </b-menu-item>
          </b-menu-item>
        </b-menu-list>
      </b-menu>
    </div>
    <div class="Profile__Page__Content">
      <router-view v-if="ready" />
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";
import ReportService from "@/services/ReportService";
import ServicesService from "@/services/ServicesService";
import store from '../store';

const reportsOptions = [
  { Value: 3, Text: 'הכל' },
  { Value: 1, Text: 'הסבת חברות' },
  { Value: 2, Text: 'הסבת נהגים' }
];

export default {
  name: "Profile",

  async created() {
    this.ready = true;
    this.startHelathCheck();
    if (this.profileId && this.$route.name !== 'addReport') {
      store.commit("auth/setProfileId", null);
      window.location.href = this.$router.resolve({ name: "admin" }).href;
    }
  },
  components: {
    vSelect
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
    profileId() {
      return this.$store.state.auth.profileId;
    },
  },
  data() {
    return {
      ready: false,
      isHealthy: null,
      reportsType: reportsOptions[0],
      reportsOptions
    };
  },
  methods: {
    startHelathCheck() {
      setInterval(() => {
        this.checkHealth().then((r) => {
          this.isHealthy = r;
        });
      }, 15000);
    },
    async checkHealth() {
      try {
        await ServicesService.healthCheck();
        return true;
      } catch {
        return false;
      }
    },
    async startTransmittion() {
      const isHealthy = await this.checkHealth();
      if (isHealthy) {
        this.$buefy.dialog.confirm({
          title: "שידור דוחות",
          message: "האם לתהחיל שידור דוחות?",
          confirmText: "כן",
          cancelText: "לא",
          type: "is-warrent",
          closeOnConfirm: true,
          hasIcon: true,
          onConfirm: (a, instance) => {
            const type = this.reportsType && this.reportsType.Value !== 3 ? this.reportsType.Value : undefined;
            ReportService.sendAllTransfers(type).then(() => {
              instance.close();
              this.$buefy.toast.open({
                type: "is-success",
                message: "השידור החל...",
                position: "is-top",
                duration: 4000,
              });
            });
          },
        });
      } else {
        this.$buefy.toast.open({
          type: "is-danger",
          message: "שירות שידור אינו פעיל!",
          position: "is-top",
          duration: 8000,
        });
      }
    },
    cancelTransmittion() {
      ReportService.cancelAllTransfers().then(() => {
        this.$buefy.toast.open({
          type: "is-info",
          message: "השידור הפסיק...",
          position: "is-top",
          duration: 8000,
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.Profile__Page {
  display: flex;

  .Profile__Page__Menu {
    flex-basis: 300px;
    background-color: #f5f8fa;
    min-height: calc(100vh - 50px);
    padding: 20px;

    h1 {
      font-size: 25px;
      padding: 5px 10px;
    }

    ::v-deep a {
      color: inherit;
      padding: 10px 10px;
      font-size: 16px;
      width: 100%;
      display: block;
      border-radius: 3px;

      &.router-link-exact-active {
        background-color: #eaf0f6;
        font-weight: 500;
      }

      &:hover {
        background-color: #eaf0f6;
      }
    }
  }

  .Profile__Page__Content {
    flex: 1;
    border-right: 1px solid #c0c0c09e;
    overflow-x: auto;
    width: calc(100% - 300px);
  }
}

::v-deep {
  .menu-list a.is-active {
    background: inherit;
  }
}
</style>
