<template>
  <div class="Reports__View">
    <EntityListLayout :tabs="tabs" ref="entity" @onNewEntitySave="onNewEntitySave">
      <template #title>דוחות</template>
      <template #button>
        <!-- <b-button @click="showNewEntity">הוספת דוח חדש</b-button> -->
        <a @click="addTask" class="link-button">
          משימה חדשה
        </a>
        <router-link class="link-button" to="/addreport">
          הוספת דוח חדש
        </router-link>
      </template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import ReportService from "@/services/ReportService";
import ProfileService from "@/services/ProfileService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import AdminReportEntity from "@/views/AdminReportEntity.vue";
import DialogAddTask from '@/components/DialogAddTask.vue';
import {
  ModalProgrammatic as Modal,
} from 'buefy';
import TableActions from "@/services/TableActionsService";
import FieldService from "@/services/FieldService";
import VehicleService from "@/services/VehicleService";
import FilterService from "@/services/FilterService";
import NewReportEntity from "@/components/Entities/NewReportEntity.vue";
import { saveAs } from "file-saver";

export default {
  name: "AdminSearchReports",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
    tabName() {
      return this.selectedTab && this.selectedTab.title;
    },
    excelMimeType() {
      return "application/vnd.ms-excel";
    },
    isLeasingCompany() {
      return this.$store.state.profile?.IsLeasingCompany;
    },
    isReady() {
      return this.isLeasingCompany === null;
    },
  },
  created() {
    this.create();
  },
  methods: {
    create() {
      const reportsFields = FieldService.list("reports");
      const reportsfilters = FilterService.list();

      const sideViewEntity = {
        component: AdminReportEntity,
        preview: true,
        readOnly: false,
        title: "פרטי דוח",
        recordUrl: "admin/reports",
      };

      const excelExport = [
        {
          title: "יצוא טבלה",
          action: this.export,
        },
        // {
        //   title: "יצוא מקובץ לפי עיריות",
        //   action: this.exportByMunic,
        // },
        // {
        //   title: "יצוא מקובץ לפי לקוח",
        //   action: this.exportByClient,
        // },
      ];
      const fields = [
        { ...reportsFields.Address, checked: false },
        { ...reportsFields.Alerts, checked: true },
        { ...reportsFields.ApprovalDate, checked: false },
        { ...reportsFields.ClientComment, checked: false },
        { ...reportsFields.CreateDate, checked: false },
        { ...reportsFields.CurrentAmount, checked: true },
        { ...reportsFields.DateTaken, checked: true },
        { ...reportsFields.Driver, checked: false },
        { ...reportsFields.DriverAmount, checked: false },
        { ...reportsFields.DriverTransferCompleteDate, checked: false },
        { ...reportsFields.DriverTransferError, checked: false },
        { ...reportsFields.DriverTransferPdfUrl, checked: true },
        { ...reportsFields.DriverTransferSignedDate, checked: false },
        { ...reportsFields.DriverTransferStatus, checked: false },
        { ...reportsFields.DriverTransferredDate, checked: false },
        { ...reportsFields.HasScreenshot, checked: false },
        { ...reportsFields.IsPaid, checked: false },
        { ...reportsFields.IsPaidByDriver, checked: false },
        { ...reportsFields.LastPayDate, checked: true },
        { ...reportsFields.ManagerComment, checked: false },
        { ...reportsFields.MuniIdNumber, checked: false },
        { ...reportsFields.MuniReferenceNumber, checked: false },
        { ...reportsFields.Municipality, checked: true },
        { ...reportsFields.OriginalAmount, checked: true },
        // { ...reportsFields.OwnerName, checked: false }, // TransferredFromName
        { ...reportsFields.PartialPaidAmount, checked: false },
        { ...reportsFields.PaymentDate, checked: false },
        { ...reportsFields.PaymentReferenceNumber, checked: false },
        { ...reportsFields.PaymentUrl, checked: true },
        {
          ...reportsFields.PlateNumber,
          checked: true,
          onClick: this.onPlateNumberClick,
          isAdmin: true
        },
        // { ...reportsFields.Profile, checked: true }, // TransferredTo
        { ...reportsFields.Reason, checked: false },
        { ...reportsFields.RelevantLaw, checked: false },
        { ...reportsFields.ReportCause, checked: false },
        { ...reportsFields.ReportNumber, checked: true, isAdmin: true },
        { ...reportsFields.Status, checked: true },
        { ...reportsFields.TransferCompleteDate, checked: false },
        { ...reportsFields.TransferError, checked: false },
        // { ...reportsFields.TransferPdfUrl, checked: true },
        { ...reportsFields.TransferSignedDate, checked: false },
        { ...reportsFields.TransferStatus, checked: false },
        { ...reportsFields.TransferredDate, checked: false },
        { ...reportsFields.VehicleType, checked: true },
        { ...reportsFields.VerificationDate, checked: false },
      ];

      const reportFields = [
        ...fields,
        { ...reportsFields.OwnerName, checked: false },
        { ...reportsFields.ProfileName, checked: true },
        { ...reportsFields.TransferPdfUrl, checked: true }
      ];
      const transfersFields = [
        ...fields,
        { ...reportsFields.TransferredFromName, checked: false },
        { ...reportsFields.TransferredTo, checked: true },
        { ...reportsFields.TransferPdfUrlAdmin, checked: true }
      ];

      this.tabs = [
        {
          id: "AllReportsAdmin",
          title: "כל הדוחות",
          newEntityComponent: NewReportEntity,
          getDataAsync: this.getAllReports,
          perPage: 20,
          fields: reportFields,
          filters: [
            { ...reportsfilters.IsPaid },
            { ...reportsfilters.ReportStatus },
            { ...reportsfilters.NotReportStatus },
            { ...reportsfilters.Reason },
            { ...reportsfilters.Alerts },
            {
              ...reportsfilters.Profile,
              OptionsAsync: ProfileService.allMapped,
              isTransferOnly: false
            },
            { ...reportsfilters.Driver },
            {
              ...reportsfilters.VehicleOwner,
              OptionsAsync: ProfileService.allMapped,
            },
            { ...reportsfilters.TransferStatusWNotTransferred },
            {
              ...reportsfilters.DriverTransferStatusWNotTransferred,
              Display: "סטטוס הסבת נהג",
              Query: "DriverTransferStatus",
              Tooltip: 'סטטוס ההסבה של נהג ע"פ המערכת',
            },
            { ...reportsfilters.ReportNumber },
            { ...reportsfilters.MuniIdNumber },
            { ...reportsfilters.PostOfficeReference },
            {
              ...reportsfilters.PlateNumber,
              selected: this.$route.query.PlateNumber,
              OptionsAsync: (term) =>
                VehicleService.searchAdminPlateNumber(term).then((r) =>
                  r.data.map((s) => ({ Text: s.Name, Value: s.Name }))
                ),
            },
            { ...reportsfilters.Municipality },
            {
              ...reportsfilters.DateTypes,
              selected: FilterService.DateSearchTypes.DateTaken,
              Options: this.isLeasingCompany
                ? reportsfilters.DateTypes.Options
                : [
                  { ...FilterService.DateSearchTypes.DateTaken },
                  { ...FilterService.DateSearchTypes.LastPayDate },
                  { ...FilterService.DateSearchTypes.PaymentDate },
                  { ...FilterService.DateSearchTypes.ApprovalDate },
                  { ...FilterService.DateSearchTypes.VerificationDate },
                  { ...FilterService.DateSearchTypes.CreateDate },
                  {
                    ...FilterService.DateSearchTypes
                      .FirstDocumentTransferCompleteDate,
                  },
                  {
                    ...FilterService.DateSearchTypes
                      .FirstDocumentTransferDriverCompleteDate,
                  },
                ],
            },
          ],
          sortField: "LastPayDate",
          sortOrder: "desc",
          checkableRows: true,
          excelExport,
          fastSearch: true,
          fastSearchOptions: {
            propName: "ReportNumber",
            label: "חיפוש לפי מספר דוח",
          },
          sideViewEntity,
          tableActions: [
            TableActions.markAsPaid,
            TableActions.driverAssign,
            TableActions.addTask,
            TableActions.downloadFiles,
            // {
            //   ...TableActions.changeTransferStatusCompany,
            //   Label: "שינוי סטטוס הסבת חברה TEST",
            // },
            {
              ...TableActions.changeTransferStatusDriver,
              Label: "שינוי סטטוס הסבת נהג",
            },
            TableActions.changeReportStatus,
            TableActions.reportsDetailsScanning,
            TableActions.reportsAlertsGenerator,
          ],
          isAdminArea: true,
          defaultQuery: false
          // defaultQuery: {
          //   IsAscending: false,
          //   SortedBy: "LastPayDate"
          // }
        },
        {
          id: "PaymentVerificationAdmin",
          title: "וידוא תשלום",
          newEntityComponent: NewReportEntity,
          getDataAsync: ({ cancelToken, setPermanentQuery, ...rest }) => {
            const query = rest;
            return this.getAllTransfers({ cancelToken, ...query });
          },
          perPage: 20,
          fields: transfersFields,
          filters: [
            { ...reportsfilters.IsPaid, selected: true },
            { ...reportsfilters.ReportStatus },
            { ...reportsfilters.NotReportStatus },
            { ...reportsfilters.Reason },
            { ...reportsfilters.Alerts },
            {
              ...reportsfilters.Profile,
              OptionsAsync: ProfileService.allMapped,
              isTransferOnly: false
            },
            { ...reportsfilters.Driver },
            {
              ...reportsfilters.VehicleOwner,
              OptionsAsync: ProfileService.allMapped,
            },
            {
              ...reportsfilters.TransferStatusWNotTransferred,
              selected: [
                1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 19,
              ],
            },
            {
              ...reportsfilters.DriverTransferStatusWNotTransferred,
              Display: "סטטוס הסבת נהג",
              Query: "DriverTransferStatus",
              Tooltip: 'סטטוס ההסבה של נהג ע"פ המערכת',
            },
            { ...reportsfilters.ReportNumber },
            { ...reportsfilters.MuniIdNumber },
            { ...reportsfilters.PostOfficeReference },
            {
              ...reportsfilters.PlateNumber,
              selected: this.$route.query.PlateNumber,
              OptionsAsync: (term) =>
                VehicleService.searchAdminPlateNumber(term).then((r) =>
                  r.data.map((s) => ({ Text: s.Name, Value: s.Name }))
                ),
            },
            { ...reportsfilters.Municipality },
            {
              ...reportsfilters.DateTypes,
              selected: FilterService.DateSearchTypes.DateTaken,
              Options: this.isLeasingCompany
                ? reportsfilters.DateTypes.Options
                : [
                  { ...FilterService.DateSearchTypes.DateTaken },
                  { ...FilterService.DateSearchTypes.LastPayDate },
                  { ...FilterService.DateSearchTypes.PaymentDate },
                  { ...FilterService.DateSearchTypes.ApprovalDate },
                  { ...FilterService.DateSearchTypes.VerificationDate },
                  { ...FilterService.DateSearchTypes.CreateDate },
                  {
                    ...FilterService.DateSearchTypes
                      .FirstDocumentTransferCompleteDate,
                  },
                  {
                    ...FilterService.DateSearchTypes
                      .FirstDocumentTransferDriverCompleteDate,
                  },
                ],
            },
          ],
          sortField: "LastPayDate",
          sortOrder: "asc",
          checkableRows: true,
          excelExport,
          fastSearch: true,
          fastSearchOptions: {
            propName: "ReportNumber",
            label: "חיפוש לפי מספר דוח",
          },
          sideViewEntity,
          tableActions: [
            TableActions.markAsPaid,
            TableActions.driverAssign,
            {
              ...TableActions.changeTransferStatusCompanyAdmin,
              Label: "שינוי סטטוס הסבת חברה",
            },
            {
              ...TableActions.changeTransferStatusDriver,
              Label: "שינוי סטטוס הסבת נהג",
            },
            TableActions.changeReportStatus,
            TableActions.reportsDetailsScanning,
            TableActions.reportsAlertsGenerator,
          ],
          isAdminArea: true,
          defaultQuery: false
          // defaultQuery: {
          //   IsAscending: true,
          //   SortedBy: "LastPayDate",
          //   TransferStatus: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 19],
          //   tab: "PaymentVerificationAdmin"
          // }
        },
        {
          id: "AllTransferCompanySendingProcessAdmin",
          title: "כל ההסבות",
          newEntityComponent: NewReportEntity,
          getDataAsync: this.getAllTransfers,
          perPage: 20,
          // fields,
          fields: this.displayFields(
            transfersFields,
            [
              "סטטוס הסבת חברה",
              "תאריך הפקת הסבת חברה",
              "קובץ הסבה לחברה",
              "חברה",
              "לקוח",
            ],
            ["סטטוס הסבת נהג", "תאריך הפקת הסבת נהג", "קובץ הסבת נהג", "נהג"]
          ),
          filters: [
            { ...reportsfilters.IsPaid },
            { ...reportsfilters.ReportStatus },
            { ...reportsfilters.NotReportStatus },
            { ...reportsfilters.Reason },
            { ...reportsfilters.Alerts },
            {
              ...reportsfilters.Profile,
              OptionsAsync: ProfileService.allMapped,
              isTransferOnly: false
            },
            { ...reportsfilters.Driver },
            {
              ...reportsfilters.VehicleOwner,
              OptionsAsync: ProfileService.allMapped,
            },
            {
              ...reportsfilters.TransferStatusWNotTransferred,
              selected: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
            },
            {
              ...reportsfilters.DriverTransferStatusWNotTransferred,
              Display: "סטטוס הסבת נהג",
              Query: "DriverTransferStatus",
              Tooltip: 'סטטוס ההסבה של נהג ע"פ המערכת',
            },
            { ...reportsfilters.ReportNumber },
            { ...reportsfilters.MuniIdNumber },
            { ...reportsfilters.PostOfficeReference },
            {
              ...reportsfilters.PlateNumber,
              selected: this.$route.query.PlateNumber,
              OptionsAsync: (term) =>
                VehicleService.searchAdminPlateNumber(term).then((r) =>
                  r.data.map((s) => ({ Text: s.Name, Value: s.Name }))
                ),
            },
            { ...reportsfilters.Municipality },
            {
              ...reportsfilters.DateTypes,
              selected: FilterService.DateSearchTypes.DateTaken,
              Options: this.isLeasingCompany
                ? reportsfilters.DateTypes.Options
                : [
                  { ...FilterService.DateSearchTypes.DateTaken },
                  { ...FilterService.DateSearchTypes.LastPayDate },
                  { ...FilterService.DateSearchTypes.PaymentDate },
                  { ...FilterService.DateSearchTypes.ApprovalDate },
                  { ...FilterService.DateSearchTypes.VerificationDate },
                  { ...FilterService.DateSearchTypes.CreateDate },
                  {
                    ...FilterService.DateSearchTypes
                      .FirstDocumentTransferCompleteDate,
                  },
                  {
                    ...FilterService.DateSearchTypes
                      .FirstDocumentTransferDriverCompleteDate,
                  },
                ],
            },
          ],
          sortField: "LastPayDate",
          sortOrder: "asc",
          checkableRows: true,
          excelExport,
          fastSearch: true,
          fastSearchOptions: {
            propName: "ReportNumber",
            label: "חיפוש לפי מספר דוח",
          },
          sideViewEntity,
          tableActions: [
            TableActions.markAsPaid,
            TableActions.driverAssign,
            TableActions.downloadFiles,
            {
              ...TableActions.changeTransferStatusCompanyAdmin,
              Label: "שינוי סטטוס הסבת חברה",
            },
            // {
            //   ...TableActions.changeTransferStatusDriver,
            //   Label: "שינוי סטטוס הסבת נהג",
            // },
            TableActions.changeReportStatus,
            TableActions.reportsDetailsScanning,
            TableActions.reportsAlertsGenerator,
          ],
          isAdminArea: true,
          defaultQuery: false
          // defaultQuery: {
          //   IsAscending: true,
          //   SortedBy: "LastPayDate",
          //   TransferStatus: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 19],
          //   tab: "AllTransferCompanySendingProcessAdmin"
          // }
        },
        {
          id: "ReportsTransferCompanyViaMail",
          title: "דוחות ממתינים לשליחה בדואר לפי סטטוס הסבת חברה",
          newEntityComponent: NewReportEntity,
          getDataAsync: this.getAllTransfers,
          perPage: 20,
          fields: transfersFields,
          filters: [
            { ...reportsfilters.IsPaid, selected: false },
            { ...reportsfilters.ReportStatus },
            { ...reportsfilters.NotReportStatus },
            { ...reportsfilters.Reason },
            { ...reportsfilters.Alerts },
            {
              ...reportsfilters.Profile,
              OptionsAsync: ProfileService.allMapped,
              isTransferOnly: false
            },
            { ...reportsfilters.Driver },
            {
              ...reportsfilters.VehicleOwner,
              OptionsAsync: ProfileService.allMapped,
            },
            {
              ...reportsfilters.TransferStatusWNotTransferred,
              selected: [11],
            },
            {
              ...reportsfilters.DriverTransferStatusWNotTransferred,
              Display: "סטטוס הסבת נהג",
              Query: "DriverTransferStatus",
              Tooltip: 'סטטוס ההסבה של נהג ע"פ המערכת',
            },
            { ...reportsfilters.ReportNumber },
            { ...reportsfilters.MuniIdNumber },
            { ...reportsfilters.PostOfficeReference },
            {
              ...reportsfilters.PlateNumber,
              selected: this.$route.query.PlateNumber,
              OptionsAsync: (term) =>
                VehicleService.searchAdminPlateNumber(term).then((r) =>
                  r.data.map((s) => ({ Text: s.Name, Value: s.Name }))
                ),
            },
            { ...reportsfilters.Municipality },
            {
              ...reportsfilters.DateTypes,
              selected: FilterService.DateSearchTypes.DateTaken,
              Options: this.isLeasingCompany
                ? reportsfilters.DateTypes.Options
                : [
                  { ...FilterService.DateSearchTypes.DateTaken },
                  { ...FilterService.DateSearchTypes.LastPayDate },
                  { ...FilterService.DateSearchTypes.PaymentDate },
                  { ...FilterService.DateSearchTypes.ApprovalDate },
                  { ...FilterService.DateSearchTypes.VerificationDate },
                  { ...FilterService.DateSearchTypes.CreateDate },
                  {
                    ...FilterService.DateSearchTypes
                      .FirstDocumentTransferCompleteDate,
                  },
                  {
                    ...FilterService.DateSearchTypes
                      .FirstDocumentTransferDriverCompleteDate,
                  },
                ],
            },
          ],
          sortField: "LastPayDate",
          sortOrder: "asc",
          checkableRows: true,
          excelExport,
          fastSearch: true,
          fastSearchOptions: {
            propName: "ReportNumber",
            label: "חיפוש לפי מספר דוח",
          },
          sideViewEntity,
          tableActions: [
            TableActions.markAsPaid,
            TableActions.driverAssign,
            {
              ...TableActions.changeTransferStatusCompanyAdmin,
              Label: "שינוי סטטוס הסבת חברה",
            },
            {
              ...TableActions.changeTransferStatusDriver,
              Label: "שינוי סטטוס הסבת נהג",
            },
            TableActions.changeReportStatus,
            TableActions.reportsDetailsScanning,
            TableActions.reportsAlertsGenerator,
          ],
          isAdminArea: true,
          defaultQuery: {
            IsAscending: true,
            SortedBy: "LastPayDate",
            TransferStatus: [11],
            tab: "ReportsTransferCompanyViaMail"
          }
        },
        {
          id: "ReportsTransferDriverViaMail",
          title: "דוחות ממתינים לשליחה בדואר לפי סטטוס הסבת נהג",
          newEntityComponent: NewReportEntity,
          getDataAsync: this.getAllReports,
          perPage: 20,
          fields: transfersFields,
          filters: [
            { ...reportsfilters.IsPaid, selected: false },
            { ...reportsfilters.ReportStatus },
            { ...reportsfilters.NotReportStatus },
            { ...reportsfilters.Reason },
            { ...reportsfilters.Alerts },
            {
              ...reportsfilters.Profile,
              OptionsAsync: ProfileService.allMapped,
              isTransferOnly: false
            },
            { ...reportsfilters.Driver },
            {
              ...reportsfilters.VehicleOwner,
              OptionsAsync: ProfileService.allMapped,
            },
            {
              ...reportsfilters.TransferStatusWNotTransferred,
            },
            {
              ...reportsfilters.DriverTransferStatusWNotTransferred,
              selected: [11],
              Display: "סטטוס הסבת נהג",
              Query: "DriverTransferStatus",
              Tooltip: 'סטטוס ההסבה של נהג ע"פ המערכת',
            },
            { ...reportsfilters.ReportNumber },
            { ...reportsfilters.MuniIdNumber },
            { ...reportsfilters.PostOfficeReference },
            {
              ...reportsfilters.PlateNumber,
              selected: this.$route.query.PlateNumber,
              OptionsAsync: (term) =>
                VehicleService.searchAdminPlateNumber(term).then((r) =>
                  r.data.map((s) => ({ Text: s.Name, Value: s.Name }))
                ),
            },
            { ...reportsfilters.Municipality },
            {
              ...reportsfilters.DateTypes,
              selected: FilterService.DateSearchTypes.DateTaken,
              Options: this.isLeasingCompany
                ? reportsfilters.DateTypes.Options
                : [
                  { ...FilterService.DateSearchTypes.DateTaken },
                  { ...FilterService.DateSearchTypes.LastPayDate },
                  { ...FilterService.DateSearchTypes.PaymentDate },
                  { ...FilterService.DateSearchTypes.ApprovalDate },
                  { ...FilterService.DateSearchTypes.VerificationDate },
                  { ...FilterService.DateSearchTypes.CreateDate },
                  {
                    ...FilterService.DateSearchTypes
                      .FirstDocumentTransferCompleteDate,
                  },
                  {
                    ...FilterService.DateSearchTypes
                      .FirstDocumentTransferDriverCompleteDate,
                  },
                ],
            },
          ],
          sortField: "LastPayDate",
          sortOrder: "asc",
          checkableRows: true,
          excelExport,
          fastSearch: true,
          fastSearchOptions: {
            propName: "ReportNumber",
            label: "חיפוש לפי מספר דוח",
          },
          sideViewEntity,
          tableActions: [
            TableActions.markAsPaid,
            TableActions.driverAssign,
            {
              ...TableActions.changeTransferStatusCompanyAdmin,
              Label: "שינוי סטטוס הסבת חברה",
            },
            {
              ...TableActions.changeTransferStatusDriver,
              Label: "שינוי סטטוס הסבת נהג",
            },
            TableActions.changeReportStatus,
            TableActions.reportsDetailsScanning,
            TableActions.reportsAlertsGenerator,
          ],
          isAdminArea: true,
          defaultQuery: {
            IsAscending: true,
            SortedBy: "LastPayDate",
            DriverTransferStatus: [11],
            tab: "ReportsTransferDriverViaMail"
          }
        },
        {
          id: "TransferCompanySendingProcessAdmin",
          title: "הסבות חברה לשידור",
          newEntityComponent: NewReportEntity,
          getDataAsync: this.getAllTransfers,
          perPage: 20,
          // fields,
          fields: this.displayFields(
            [...transfersFields, { ...reportsFields.TransferCount, checked: true }],
            [
              "סטטוס הסבת חברה",
              "תאריך הפקת הסבת חברה",
              "קובץ הסבה לחברה",
              "חברה",
              "לקוח",
            ],
            ["סטטוס הסבת נהג", "תאריך הפקת הסבת נהג", "קובץ הסבת נהג", "נהג"]
          ),
          filters: [
            { ...reportsfilters.IsPaid, selected: false },
            { ...reportsfilters.ReportStatus },
            { ...reportsfilters.NotReportStatus },
            { ...reportsfilters.Reason },
            { ...reportsfilters.Alerts },
            {
              ...reportsfilters.Profile,
              OptionsAsync: ProfileService.allMapped,
              isTransferOnly: false
            },
            { ...reportsfilters.Driver },
            {
              ...reportsfilters.VehicleOwner,
              OptionsAsync: ProfileService.allMapped,
            },
            {
              ...reportsfilters.TransferStatusWNotTransferred,
              selected: [10],
            },
            {
              ...reportsfilters.DriverTransferStatusWNotTransferred,
              Display: "סטטוס הסבת נהג",
              Query: "DriverTransferStatus",
              Tooltip: 'סטטוס ההסבה של נהג ע"פ המערכת',
            },
            { ...reportsfilters.ReportNumber },
            { ...reportsfilters.MuniIdNumber },
            { ...reportsfilters.PostOfficeReference },
            {
              ...reportsfilters.PlateNumber,
              selected: this.$route.query.PlateNumber,
              OptionsAsync: (term) =>
                VehicleService.searchAdminPlateNumber(term).then((r) =>
                  r.data.map((s) => ({ Text: s.Name, Value: s.Name }))
                ),
            },
            { ...reportsfilters.Municipality },
            {
              ...reportsfilters.DateTypes,
              selected: FilterService.DateSearchTypes.DateTaken,
              Options: this.isLeasingCompany
                ? reportsfilters.DateTypes.Options
                : [
                  { ...FilterService.DateSearchTypes.DateTaken },
                  { ...FilterService.DateSearchTypes.LastPayDate },
                  { ...FilterService.DateSearchTypes.PaymentDate },
                  { ...FilterService.DateSearchTypes.ApprovalDate },
                  { ...FilterService.DateSearchTypes.VerificationDate },
                  { ...FilterService.DateSearchTypes.CreateDate },
                  {
                    ...FilterService.DateSearchTypes
                      .FirstDocumentTransferCompleteDate,
                  },
                  {
                    ...FilterService.DateSearchTypes
                      .FirstDocumentTransferDriverCompleteDate,
                  },
                ],
            },
          ],
          sortField: "LastPayDate",
          sortOrder: "asc",
          checkableRows: true,
          excelExport,
          fastSearch: true,
          fastSearchOptions: {
            propName: "ReportNumber",
            label: "חיפוש לפי מספר דוח",
          },
          sideViewEntity,
          tableActions: [
            TableActions.markAsPaid,
            TableActions.driverAssign,
            {
              ...TableActions.changeTransferStatusCompanyAdmin,
              Label: "שינוי סטטוס הסבת חברה",
            },
            // {
            //   ...TableActions.changeTransferStatusDriver,
            //   Label: "שינוי סטטוס הסבת נהג",
            // },
            TableActions.changeReportStatus,
            TableActions.reportsDetailsScanning,
            TableActions.reportsAlertsGenerator,
          ],
          isAdminArea: true,
          defaultQuery: {
            IsAscending: true,
            SortedBy: "LastPayDate",
            TransferStatus: [10],
            tab: "TransferCompanySendingProcessAdmin"
          }
        },
        {
          id: "TransferCompanySendAgainAdmin",
          title: "הסבות חברה ש.חוזר",
          newEntityComponent: NewReportEntity,
          getDataAsync: this.getAllTransfers,
          perPage: 20,
          fields: this.displayFields(
            [...transfersFields, { ...reportsFields.TransferCount, checked: true }],
            [
              "סטטוס הסבת חברה",
              "תאריך הפקת הסבת חברה",
              "קובץ הסבה לחברה",
              "חברה",
              "לקוח",
            ],
            ["סטטוס הסבת נהג", "תאריך הפקת הסבת נהג", "קובץ הסבת נהג", "נהג"]
          ),
          filters: [
            { ...reportsfilters.IsPaid, selected: false },
            { ...reportsfilters.ReportStatus },
            { ...reportsfilters.NotReportStatus },
            { ...reportsfilters.Reason },
            { ...reportsfilters.Alerts },
            {
              ...reportsfilters.Profile,
              OptionsAsync: ProfileService.allMapped,
              isTransferOnly: false
            },
            { ...reportsfilters.Driver },
            {
              ...reportsfilters.VehicleOwner,
              OptionsAsync: ProfileService.allMapped,
            },
            {
              ...reportsfilters.TransferStatusWNotTransferred,
              selected: [9],
            },
            {
              ...reportsfilters.DriverTransferStatusWNotTransferred,
              Display: "סטטוס הסבת נהג",
              Query: "DriverTransferStatus",
              Tooltip: 'סטטוס ההסבה של נהג ע"פ המערכת',
            },
            { ...reportsfilters.ReportNumber },
            { ...reportsfilters.MuniIdNumber },
            { ...reportsfilters.PostOfficeReference },
            {
              ...reportsfilters.PlateNumber,
              selected: this.$route.query.PlateNumber,
              OptionsAsync: (term) =>
                VehicleService.searchAdminPlateNumber(term).then((r) =>
                  r.data.map((s) => ({ Text: s.Name, Value: s.Name }))
                ),
            },
            { ...reportsfilters.Municipality },
            {
              ...reportsfilters.DateTypes,
              selected: FilterService.DateSearchTypes.DateTaken,
              Options: this.isLeasingCompany
                ? reportsfilters.DateTypes.Options
                : [
                  { ...FilterService.DateSearchTypes.DateTaken },
                  { ...FilterService.DateSearchTypes.LastPayDate },
                  { ...FilterService.DateSearchTypes.PaymentDate },
                  { ...FilterService.DateSearchTypes.ApprovalDate },
                  { ...FilterService.DateSearchTypes.VerificationDate },
                  { ...FilterService.DateSearchTypes.CreateDate },
                  {
                    ...FilterService.DateSearchTypes
                      .FirstDocumentTransferCompleteDate,
                  },
                  {
                    ...FilterService.DateSearchTypes
                      .FirstDocumentTransferDriverCompleteDate,
                  },
                ],
            },
          ],
          sortField: "LastPayDate",
          sortOrder: "asc",
          checkableRows: true,
          excelExport,
          fastSearch: true,
          fastSearchOptions: {
            propName: "ReportNumber",
            label: "חיפוש לפי מספר דוח",
          },
          sideViewEntity,
          tableActions: [
            TableActions.markAsPaid,
            TableActions.driverAssign,
            {
              ...TableActions.changeTransferStatusCompanyAdminLimited,
              Label: "שינוי סטטוס הסבת חברה",
            },
            // {
            //   ...TableActions.changeTransferStatusDriver,
            //   Label: "שינוי סטטוס הסבת נהג",
            // },
            TableActions.changeReportStatus,
            TableActions.reportsDetailsScanning,
            TableActions.reportsAlertsGenerator,
          ],
          isAdminArea: true,
          defaultQuery: {
            IsAscending: true,
            SortedBy: "LastPayDate",
            TransferStatus: [9],
            tab: "TransferCompanySendAgainAdmin"
          }
        },
        {
          id: "TransferCompanyDelayedAdmin",
          title: "הסבות חברה בהשהייה",
          newEntityComponent: NewReportEntity,
          getDataAsync: this.getAllTransfers,
          perPage: 20,
          fields: this.displayFields(
            [...transfersFields, { ...reportsFields.TransferCount, checked: true }],
            [
              "סטטוס הסבת חברה",
              "תאריך הפקת הסבת חברה",
              "קובץ הסבה לחברה",
              "חברה",
              "לקוח",
            ],
            ["סטטוס הסבת נהג", "תאריך הפקת הסבת נהג", "קובץ הסבת נהג", "נהג"]
          ),
          filters: [
            { ...reportsfilters.IsPaid, selected: false },
            { ...reportsfilters.ReportStatus },
            { ...reportsfilters.NotReportStatus },
            { ...reportsfilters.Reason },
            { ...reportsfilters.Alerts },
            {
              ...reportsfilters.Profile,
              OptionsAsync: ProfileService.allMapped,
              isTransferOnly: false
            },
            { ...reportsfilters.Driver },
            {
              ...reportsfilters.VehicleOwner,
              OptionsAsync: ProfileService.allMapped,
            },
            {
              ...reportsfilters.TransferStatusWNotTransferred,
              selected: [16],
            },
            {
              ...reportsfilters.DriverTransferStatusWNotTransferred,
              Display: "סטטוס הסבת נהג",
              Query: "DriverTransferStatus",
              Tooltip: 'סטטוס ההסבה של נהג ע"פ המערכת',
            },
            { ...reportsfilters.ReportNumber },
            { ...reportsfilters.MuniIdNumber },
            { ...reportsfilters.PostOfficeReference },
            {
              ...reportsfilters.PlateNumber,
              selected: this.$route.query.PlateNumber,
              OptionsAsync: (term) =>
                VehicleService.searchAdminPlateNumber(term).then((r) =>
                  r.data.map((s) => ({ Text: s.Name, Value: s.Name }))
                ),
            },
            { ...reportsfilters.Municipality },
            {
              ...reportsfilters.DateTypes,
              selected: FilterService.DateSearchTypes.DateTaken,
              Options: this.isLeasingCompany
                ? reportsfilters.DateTypes.Options
                : [
                  { ...FilterService.DateSearchTypes.DateTaken },
                  { ...FilterService.DateSearchTypes.LastPayDate },
                  { ...FilterService.DateSearchTypes.PaymentDate },
                  { ...FilterService.DateSearchTypes.ApprovalDate },
                  { ...FilterService.DateSearchTypes.VerificationDate },
                  { ...FilterService.DateSearchTypes.CreateDate },
                  {
                    ...FilterService.DateSearchTypes
                      .FirstDocumentTransferCompleteDate,
                  },
                  {
                    ...FilterService.DateSearchTypes
                      .FirstDocumentTransferDriverCompleteDate,
                  },
                ],
            },
          ],
          sortField: "LastPayDate",
          sortOrder: "asc",
          checkableRows: true,
          excelExport,
          fastSearch: true,
          fastSearchOptions: {
            propName: "ReportNumber",
            label: "חיפוש לפי מספר דוח",
          },
          sideViewEntity,
          tableActions: [
            TableActions.markAsPaid,
            TableActions.driverAssign,
            {
              ...TableActions.changeTransferStatusCompanyAdmin,
              Label: "שינוי סטטוס הסבת חברה",
            },
            // {
            //   ...TableActions.changeTransferStatusDriver,
            //   Label: "שינוי סטטוס הסבת נהג",
            // },
            TableActions.changeReportStatus,
            TableActions.reportsDetailsScanning,
            TableActions.reportsAlertsGenerator,
          ],
          isAdminArea: true,
          defaultQuery: {
            IsAscending: true,
            SortedBy: "LastPayDate",
            TransferStatus: [16],
            tab: "TransferCompanyDelayedAdmin"
          }
        },
        {
          id: "TransferDriversSendingProcessAdmin",
          title: "הסבות נהגים לשידור",
          newEntityComponent: NewReportEntity,
          getDataAsync: this.getAllReports,
          perPage: 20,
          fields: this.displayFields(
            [...reportFields, { ...reportsFields.TransferCount, checked: true }],
            [
              "סטטוס הסבת נהג",
              "תאריך הפקת הסבת נהג",
              "קובץ הסבת נהג",
              "חברה",
              "לקוח",
              "נהג",
            ],
            ["סטטוס הסבת חברה", "תאריך הפקת הסבת חברה", "קובץ הסבה לחברה"]
          ),
          filters: [
            { ...reportsfilters.IsPaid, selected: false },
            { ...reportsfilters.ReportStatus },
            { ...reportsfilters.NotReportStatus },
            { ...reportsfilters.Reason },
            { ...reportsfilters.Alerts },
            {
              ...reportsfilters.Profile,
              OptionsAsync: ProfileService.allMapped,
              isTransferOnly: false
            },
            { ...reportsfilters.Driver },
            {
              ...reportsfilters.VehicleOwner,
              OptionsAsync: ProfileService.allMapped,
            },
            {
              ...reportsfilters.TransferStatusWNotTransferred,
            },
            {
              ...reportsfilters.DriverTransferStatusWNotTransferred,
              selected: [10],
              Display: "סטטוס הסבת נהג",
              Query: "DriverTransferStatus",
              Tooltip: 'סטטוס ההסבה של נהג ע"פ המערכת',
            },
            { ...reportsfilters.ReportNumber },
            { ...reportsfilters.MuniIdNumber },
            { ...reportsfilters.PostOfficeReference },
            {
              ...reportsfilters.PlateNumber,
              selected: this.$route.query.PlateNumber,
              OptionsAsync: (term) =>
                VehicleService.searchAdminPlateNumber(term).then((r) =>
                  r.data.map((s) => ({ Text: s.Name, Value: s.Name }))
                ),
            },
            { ...reportsfilters.Municipality },
            {
              ...reportsfilters.DateTypes,
              selected: FilterService.DateSearchTypes.DateTaken,
              Options: this.isLeasingCompany
                ? reportsfilters.DateTypes.Options
                : [
                  { ...FilterService.DateSearchTypes.DateTaken },
                  { ...FilterService.DateSearchTypes.LastPayDate },
                  { ...FilterService.DateSearchTypes.PaymentDate },
                  { ...FilterService.DateSearchTypes.ApprovalDate },
                  { ...FilterService.DateSearchTypes.VerificationDate },
                  { ...FilterService.DateSearchTypes.CreateDate },
                  {
                    ...FilterService.DateSearchTypes
                      .FirstDocumentTransferCompleteDate,
                  },
                  {
                    ...FilterService.DateSearchTypes
                      .FirstDocumentTransferDriverCompleteDate,
                  },
                ],
            },
          ],
          sortField: "LastPayDate",
          sortOrder: "asc",
          checkableRows: true,
          excelExport,
          fastSearch: true,
          fastSearchOptions: {
            propName: "ReportNumber",
            label: "חיפוש לפי מספר דוח",
          },
          sideViewEntity,
          tableActions: [
            TableActions.markAsPaid,
            TableActions.driverAssign,
            // {
            //   ...TableActions.changeTransferStatusCompany,
            //   Label: "שינוי סטטוס הסבת חברה",
            // },
            {
              ...TableActions.changeTransferStatusDriver,
              Label: "שינוי סטטוס הסבת נהג",
            },
            TableActions.changeReportStatus,
            TableActions.reportsDetailsScanning,
            TableActions.reportsAlertsGenerator,
          ],
          isAdminArea: true,
          defaultQuery: {
            IsAscending: true,
            SortedBy: "LastPayDate",
            DriverTransferStatus: [10],
            tab: "TransferDriversSendingProcessAdmin"
          }
        },
        {
          id: "TransferDriversSendAgainAdmin",
          title: "הסבות נהגים ש.חוזר",
          newEntityComponent: NewReportEntity,
          getDataAsync: this.getAllReports,
          perPage: 20,
          fields: this.displayFields(
            [...reportFields, { ...reportsFields.TransferCount, checked: true }],
            [
              "סטטוס הסבת נהג",
              "תאריך הפקת הסבת נהג",
              "קובץ הסבת נהג",
              "חברה",
              "לקוח",
              "נהג",
            ],
            ["סטטוס הסבת חברה", "תאריך הפקת הסבת חברה", "קובץ הסבה לחברה"]
          ),
          filters: [
            { ...reportsfilters.IsPaid, selected: false },
            { ...reportsfilters.ReportStatus },
            { ...reportsfilters.NotReportStatus },
            { ...reportsfilters.Reason },
            { ...reportsfilters.Alerts },
            {
              ...reportsfilters.Profile,
              OptionsAsync: ProfileService.allMapped,
              isTransferOnly: false
            },
            { ...reportsfilters.Driver },
            {
              ...reportsfilters.VehicleOwner,
              OptionsAsync: ProfileService.allMapped,
            },
            {
              ...reportsfilters.TransferStatusWNotTransferred,
            },
            {
              ...reportsfilters.DriverTransferStatusWNotTransferred,
              selected: [9],
              Display: "סטטוס הסבת נהג",
              Query: "DriverTransferStatus",
              Tooltip: 'סטטוס ההסבה של נהג ע"פ המערכת',
            },
            { ...reportsfilters.ReportNumber },
            { ...reportsfilters.MuniIdNumber },
            { ...reportsfilters.PostOfficeReference },
            {
              ...reportsfilters.PlateNumber,
              selected: this.$route.query.PlateNumber,
              OptionsAsync: (term) =>
                VehicleService.searchAdminPlateNumber(term).then((r) =>
                  r.data.map((s) => ({ Text: s.Name, Value: s.Name }))
                ),
            },
            { ...reportsfilters.Municipality },
            {
              ...reportsfilters.DateTypes,
              selected: FilterService.DateSearchTypes.DateTaken,
              Options: this.isLeasingCompany
                ? reportsfilters.DateTypes.Options
                : [
                  { ...FilterService.DateSearchTypes.DateTaken },
                  { ...FilterService.DateSearchTypes.LastPayDate },
                  { ...FilterService.DateSearchTypes.PaymentDate },
                  { ...FilterService.DateSearchTypes.ApprovalDate },
                  { ...FilterService.DateSearchTypes.VerificationDate },
                  { ...FilterService.DateSearchTypes.CreateDate },
                  {
                    ...FilterService.DateSearchTypes
                      .FirstDocumentTransferCompleteDate,
                  },
                  {
                    ...FilterService.DateSearchTypes
                      .FirstDocumentTransferDriverCompleteDate,
                  },
                ],
            },
          ],
          sortField: "LastPayDate",
          sortOrder: "asc",
          checkableRows: true,
          excelExport,
          fastSearch: true,
          fastSearchOptions: {
            propName: "ReportNumber",
            label: "חיפוש לפי מספר דוח",
          },
          sideViewEntity,
          tableActions: [
            TableActions.markAsPaid,
            TableActions.driverAssign,
            // {
            //   ...TableActions.changeTransferStatusCompany,
            //   Label: "שינוי סטטוס הסבת חברה",
            // },
            {
              ...TableActions.changeTransferStatusDriverAdmin,
              Label: "שינוי סטטוס הסבת נהג",
            },
            TableActions.changeReportStatus,
            TableActions.reportsDetailsScanning,
            TableActions.reportsAlertsGenerator,
          ],
          isAdminArea: true,
          defaultQuery: {
            IsAscending: true,
            SortedBy: "LastPayDate",
            DriverTransferStatus: [9],
            tab: "TransferDriversSendAgainAdmin"
          }
        },
        {
          id: "TransferDriversDelayedAdmin",
          title: "הסבות נהגים בהשהייה",
          newEntityComponent: NewReportEntity,
          getDataAsync: this.getAllReports,
          perPage: 20,
          fields: this.displayFields(
            [...reportFields, { ...reportsFields.TransferCount, checked: true }],
            [
              "סטטוס הסבת נהג",
              "תאריך הפקת הסבת נהג",
              "קובץ הסבת נהג",
              "חברה",
              "לקוח",
              "נהג",
            ],
            ["סטטוס הסבת חברה", "תאריך הפקת הסבת חברה", "קובץ הסבה לחברה"]
          ),
          filters: [
            { ...reportsfilters.IsPaid, selected: false },
            { ...reportsfilters.ReportStatus },
            { ...reportsfilters.NotReportStatus },
            { ...reportsfilters.Reason },
            { ...reportsfilters.Alerts },
            {
              ...reportsfilters.Profile,
              OptionsAsync: ProfileService.allMapped,
              isTransferOnly: false
            },
            { ...reportsfilters.Driver },
            {
              ...reportsfilters.VehicleOwner,
              OptionsAsync: ProfileService.allMapped,
            },
            {
              ...reportsfilters.TransferStatusWNotTransferred,
            },
            {
              ...reportsfilters.DriverTransferStatusWNotTransferred,
              selected: [16],
              Display: "סטטוס הסבת נהג",
              Query: "DriverTransferStatus",
              Tooltip: 'סטטוס ההסבה של נהג ע"פ המערכת',
            },
            { ...reportsfilters.ReportNumber },
            { ...reportsfilters.MuniIdNumber },
            { ...reportsfilters.PostOfficeReference },
            {
              ...reportsfilters.PlateNumber,
              selected: this.$route.query.PlateNumber,
              OptionsAsync: (term) =>
                VehicleService.searchAdminPlateNumber(term).then((r) =>
                  r.data.map((s) => ({ Text: s.Name, Value: s.Name }))
                ),
            },
            { ...reportsfilters.Municipality },
            {
              ...reportsfilters.DateTypes,
              selected: FilterService.DateSearchTypes.DateTaken,
              Options: this.isLeasingCompany
                ? reportsfilters.DateTypes.Options
                : [
                  { ...FilterService.DateSearchTypes.DateTaken },
                  { ...FilterService.DateSearchTypes.LastPayDate },
                  { ...FilterService.DateSearchTypes.PaymentDate },
                  { ...FilterService.DateSearchTypes.ApprovalDate },
                  { ...FilterService.DateSearchTypes.VerificationDate },
                  { ...FilterService.DateSearchTypes.CreateDate },
                  {
                    ...FilterService.DateSearchTypes
                      .FirstDocumentTransferCompleteDate,
                  },
                  {
                    ...FilterService.DateSearchTypes
                      .FirstDocumentTransferDriverCompleteDate,
                  },
                ],
            },
          ],
          sortField: "LastPayDate",
          sortOrder: "asc",
          checkableRows: true,
          excelExport,
          fastSearch: true,
          fastSearchOptions: {
            propName: "ReportNumber",
            label: "חיפוש לפי מספר דוח",
          },
          sideViewEntity,
          tableActions: [
            TableActions.markAsPaid,
            TableActions.driverAssign,
            // {
            //   ...TableActions.changeTransferStatusCompany,
            //   Label: "שינוי סטטוס הסבת חברה",
            // },
            {
              ...TableActions.changeTransferStatusDriver,
              Label: "שינוי סטטוס הסבת נהג",
            },
            TableActions.changeReportStatus,
            TableActions.reportsDetailsScanning,
            TableActions.reportsAlertsGenerator,
          ],
          isAdminArea: true,
          defaultQuery: {
            IsAscending: true,
            SortedBy: "LastPayDate",
            DriverTransferStatus: [16],
            tab: "TransferDriversDelayedAdmin"
          }
        },
      ];
    },
    async onPlateNumberClick(context) {
      context.default();
    },
    showNewEntity() {
      this.$refs.entity.newEntityMenu();
    },
    onNewEntitySave() {
      this.$refs.entity.onNewEntityCancel();
    },
    export({ loading, query }) {
      loading(true);
      ReportService.exportXslAdmin(query)
        .then((r) => {
          this.saveFile(r.data, "יצוא דוחות ממערכת Betterway");
        })
        .finally(() => {
          loading(false);
        });
    },
    exportByMunic({ loading, query }) {
      loading(true);
      ReportService.exportXsl(query, "Municipalities")
        .then((r) => {
          this.saveFile(r.data, "יצוא דוחות ממערכת Betterway מקובץ לפי עיריות");
        })
        .catch(() => { })
        .finally(() => {
          loading(false);
        });
    },
    exportByClient({ loading, query }) {
      loading(true);
      ReportService.exportXsl(query, "Profiles")
        .then((r) => {
          this.saveFile(r.data, "יצוא דוחות ממערכת Betterway מקובץ לפי לקוחות");
        })
        .catch(() => { })
        .finally(() => {
          loading(false);
        });
    },
    getAllReports({ cancelToken, ...rest }) {
      return ReportService.adminSearch(
        {
          ...rest,
        },
        cancelToken.token
      );
    },
    getAllTransfers({ cancelToken, ...rest }) {
      return ReportService.adminSearchTransfers(
        {
          ...rest,
        },
        cancelToken.token
      );
    },
    getPayments({ cancelToken }) {
      return ReportService.getPayments(cancelToken.token).then((r) => ({
        data: {
          TotalResults: r.data.length,
          Items: r.data,
        },
      }));
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
    saveFile(data, filename) {
      const blob = new Blob([data], { type: this.excelMimeType });
      saveAs(blob, `${filename}.xlsx`);
    },
    checkFields(fields, idxFieldToCheck) {
      const newFields = [...fields];
      newFields.splice(idxFieldToCheck, 1, {
        ...newFields[idxFieldToCheck],
        checked: true,
      });
      return newFields;
    },
    displayFields(fields, fieldsDisplayTrueArr, fieldsDisplayFalseArr) {
      const newFields = [...fields];
      newFields.forEach((field, idx) => {
        if (fieldsDisplayTrueArr.includes(field.Display)) {
          newFields.splice(idx, 1, {
            ...newFields[idx],
            checked: true,
          });
        }
        if (fieldsDisplayFalseArr.includes(field.Display)) {
          newFields.splice(idx, 1, {
            ...newFields[idx],
            checked: false,
          });
        }
      });
      return newFields;
    },
    addTask() {
      Modal.open({
        component: DialogAddTask,
        props: {
          reportNumbers: [{ Id: 1, reportNumber: 123 }, { Id: 2, reportNumber: 3456 }],
          store: this.$store
        },
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
      });
    }
  },
  watch: {
    isReady(value) {
      if (value) {
        this.create();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.Reports__View {
  height: 100%;

  .link-button {
    background: #ff7a59;
    border: none;
    border-radius: 3px;
    padding: 14px 25px;
    margin-left: 10px;
    color: white;
    cursor: pointer;
  }
}
</style>
