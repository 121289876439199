<template>
  <div class="Reports__Import__View">
    <h1>ייבוא רכבים</h1>
    <div class="Reports__Import__View__Steps">
      <section>
        <b-steps v-model="activeStep">
          <b-step-item :label="'בחירת קובץ'">
            <div class="ChooseFile__Step">
              <b-field label="בחר קובץ לייבוא">
                <b-field class="file is-primary" :class="{ 'has-name': !!file }">
                  <b-upload v-model="file" class="file-label" rounded>
                    <span class="file-cta">
                      <b-icon class="file-icon" icon="upload"></b-icon>
                      <span class="file-label">בחר קובץ...</span>
                    </span>
                    <span class="file-name" v-if="file">
                      {{ file.name }}
                    </span>
                  </b-upload>
                  <a href="https://api.betterway.co.il/ImportSamples/תבנית יבוא רכבים.xlsx" target="_blank" style="margin-right: 10px;">
                          <b-button>
                            קובץ דוגמא
                          </b-button>
                        </a>
                </b-field>
              </b-field>
            </div>
          </b-step-item>
          <b-step-item :clickable="false" :label="'הבאת מידע'">
            <div class="Progress__Step">
              <b-progress type="is-primary" :value="progress" show-value format="percent"></b-progress>
            </div>
          </b-step-item>
          <b-step-item :label="'מיפוי שדות'">
            <div class="starting-row">
              <b-field label="ייבא נתונים החל משורה">
                <b-numberinput min="0" v-model="startingRow"></b-numberinput>
              </b-field>
              <!-- <b-tooltip label='זיהוי חכם מנסה לזהות את שדות החובה ע"פ שם הכותרת. במידה ואין כותרות, לא יתבצע זיהוי.'
                  position="is-left">
                  <b-button @click="smartAnalyze" class="mr-3" type="is-primary">בצע זיהוי חכם</b-button>
                </b-tooltip> -->
            </div>
            <div class="table-scrollable">
              <table-selectable ref="table" v-if="headers.length" :show-row-number="true" :starting-row="startingRow"
                :headers="headers" :data="table" :slicedData="slicedData" :isResetHeaders="isResetHeaders" />
            </div>
          </b-step-item>
          <b-step-item :label="'הזדהות'">
            <div class="settings-step">
              <b-field label="Api Key">
                <b-input v-model="apiKey" dir="ltr" value="123" type="password" password-reveal></b-input>
              </b-field>
              <b-field label="Api Secret">
                <b-input v-model="secretKey" dir="ltr" value="123" type="password" password-reveal></b-input>
              </b-field>
            </div>
          </b-step-item>
          <b-step-item :label="'מייבא נתונים'" :visible="isImporting">
            <div class="importing-step">
              <b-loading :is-full-page="false" v-model="isImporting" :can-cancel="false"></b-loading>
            </div>
          </b-step-item>
          <b-step-item :label="'סטטוס'">
            <div class="status-step">
              <div class="results-reports">
                <div style="position: relative; display: flex; align-items: center; gap: 10px">
                  <div>רכב: {{ `${totalCars} / ${currentCar}` }}</div>
                  <b-icon v-if="isLoading" pack="fas" icon="sync-alt" custom-class="fa-spin">
                  </b-icon>
                </div>
                <p v-if="this.succeeded">רכבים שהתקבלו: {{ succeeded }}</p>
                <p v-if="this.notSucceeded">רכבים שלא התקבלו: {{ notSucceeded }}</p>
              </div>
              <div class="is-flex is-justify-content-flex-end">
                <b-tooltip label="ייצוא לאקסל">
                  <b-button @click="exportToExcel">
                    <b-icon icon="file-excel-outline"> </b-icon>
                  </b-button>
                </b-tooltip>
              </div>
              <table class="table">
                <thead>
                  <tr>
                    <th v-for="(header, index) in results.headers" :key="index">
                      {{ header }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr :class="{ 'has-error': !row.Succeeded }" v-for="(row, rowIndex) in results.rows" :key="rowIndex">
                    <td v-for="(value, valueIndex) in row.data" :key="valueIndex">
                      {{ value }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-step-item>

          <template #navigation="{ previous, next }">
            <div class="TollRoads__Import__View__Steps__Navigations" v-show="activeStep !== 5">
              <b-button outlined type="is-primary" icon="" @click.prevent="onPrevious(previous)">
                חזור
              </b-button>
              <b-button outlined type="is-primary" icon="" @click.prevent="onNext(next)">
                המשך
              </b-button>
            </div>
          </template>
          <template v-if="activeStep === 5">
            <div class="mt-5">
              <b-button type="is-primary" @click="reset">התחל ייבוא חדש</b-button>
            </div>
          </template>
        </b-steps>
      </section>
    </div>
  </div>
</template>
<script>
import FilesService from "@/services/FilesService";
import TableSelectable from "@/components/TableSelectable.vue";
import ExternalLeasingCompaniesService from "@/services/ExternalLeasingCompaniesService";
import { saveAs } from "file-saver";
import moment from "moment";

const headers = [
  { Name: "PlateNumber", IsRequired: true, Type: "String" },
  { Name: "ClientName", IsRequired: true, Type: "String" },
  { Name: "ClientIdNumber", IsRequired: true, Type: "Double" },
  { Name: "ContractStartDate", IsRequired: true, Type: "DateTime" },
  { Name: "ContractEndDate", IsRequired: true, Type: "DateTime" },
  { Name: "VehicleReturnDate", IsRequired: false, Type: "DateTime" },
  { Name: "OwnershipStartDate", IsRequired: true, Type: "DateTime" },
  { Name: "OwnershipEndDate", IsRequired: false, Type: "DateTime" },
  { Name: "ClientCity", IsRequired: false, Type: "String" },
  { Name: "ClientStreet", IsRequired: false, Type: "String" },
  { Name: "ClientZipCode", IsRequired: false, Type: "String" },
  { Name: "ClientHouseNumber", IsRequired: false, Type: "String" },
  { Name: "VehicleType", IsRequired: false, Type: "String" },
  { Name: "Model", IsRequired: false, Type: "String" },
  { Name: "IsSellContract", IsRequired: false, Type: "Any" },
];

export default {
  components: { TableSelectable },
  name: "AdminImportVehicles",
  data() {
    return {
      apiKey: null,
      secretKey: null,
      isImporting: false,
      isResetHeaders: false,
      activeStep: 0,
      succeeded: 0,
      notSucceeded: 0,
      currentCar: 0,
      isLoading: false,
      totalCars: 0,
      file: null,
      randomProgressRef: null,
      progress: 0,
      data: [],
      results: {
        headers: [],
        rows: [],
      },
      startingRow: 1,
      headers: [],
      currentPage: 1,
      perPage: 30,
      headerFields: [],
    };
  },
  computed: {
    table() {
      return this.data?.data;
    },
    slicedData() {
      return this.data?.data.slice(0, 50);
    },
    excelMimeType() {
      return "application/vnd.ms-excel";
    },
  },
  methods: {
    async exportToExcel() {
      let data = [];
      data.push(this.results.headers);
      data = data.concat(this.results.rows.map((i) => i.data));
      const { data: r } = await FilesService.postData(data);
      const blob = new Blob([r], { type: this.excelMimeType });
      saveAs(blob, "ייבוא רכבים.xlsx");
    },
    reset() {
      this.activeStep = 0;
      this.progress = 0;
      this.file = null;
      this.data = [];
      this.startingRow = 1;
      this.currentPage = 1;
      this.perPage = 30;
      this.headers = [];
      this.results = {
        headers: [],
        rows: [],
      };
      this.isResetHeaders = false;
    },
    smartAnalyze() {
      this.$buefy.dialog.confirm({
        title: "זיהוי חכם",
        message:
          "שים לב, המערכת תבצע עבורך זיהוי חכם. במידה ויימצא שדה מתאים היא תדרוס את הבחירה הקיימת. האם אתה בטוח שברצונך להמשיך?",
        confirmText: "כן",
        cancelText: "לא",
        onConfirm: () => this.$refs.table.analyze(),
      });
    },
    async onNext() {
      if (this.activeStep === 0 && this.file) {
        await this.step1();
        return;
      }
      if (this.activeStep === 2) {
        this.step2();
        return;
      }
      if (this.activeStep === 3) {
        this.step3();
      }
    },
    async step3() {
      this.activeStep += 1;

      const exportedData = this.$refs.table.export();
      const data = exportedData.rows.map((row) => ({
        PlateNumber: row[exportedData.columns.indexOf("PlateNumber")],
        ClientName: row[exportedData.columns.indexOf("ClientName")],
        ClientIdNumber: row[exportedData.columns.indexOf("ClientIdNumber")],
        ContractStartDate: moment(
          row[exportedData.columns.indexOf("ContractStartDate")],
          "DD-MM-YYYYThh:mm:ss"
        ).format("YYYY-MM-DDTHH:mm:ss"),
        ContractEndDate: moment(
          row[exportedData.columns.indexOf("ContractEndDate")],
          "DD-MM-YYYYThh:mm:ss"
        ).format("YYYY-MM-DDTHH:mm:ss"),
        VehicleReturnDate: row[
          exportedData.columns.indexOf("VehicleReturnDate")
        ]
          ? moment(
            row[exportedData.columns.indexOf("VehicleReturnDate")],
            "DD-MM-YYYYThh:mm:ss"
          ).format("YYYY-MM-DDTHH:mm:ss")
          : "",
        OwnershipStartDate: moment(
          row[exportedData.columns.indexOf("OwnershipStartDate")],
          "DD-MM-YYYYThh:mm:ss"
        ).format("YYYY-MM-DDTHH:mm:ss"),
        OwnershipEndDate: row[exportedData.columns.indexOf("OwnershipEndDate")]
          ? moment(
            row[exportedData.columns.indexOf("OwnershipEndDate")],
            "DD-MM-YYYYThh:mm:ss"
          ).format("YYYY-MM-DDTHH:mm:ss")
          : "",
        ClientCity: row[exportedData.columns.indexOf("ClientCity")],
        ClientStreet: row[exportedData.columns.indexOf("ClientStreet")],
        ClientZipCode: row[exportedData.columns.indexOf("ClientZipCode")],
        ClientHouseNumber:
          row[exportedData.columns.indexOf("ClientHouseNumber")],
        VehicleType: row[exportedData.columns.indexOf("VehicleType")],
        Model: row[exportedData.columns.indexOf("Model")],
        IsSellContract: row[exportedData.columns.indexOf("IsSellContract")],
      }));
      this.activeStep += 1;
      let succeededRow = 0;
      let notSucceededRow = 0;
      this.isResetHeaders = true;
      const mappedHeaders = headers.map((i) => i.Name);
      this.results.headers = mappedHeaders;
      mappedHeaders.push("Error");
      this.totalCars = exportedData.rows.length;
      this.isLoading = true;
      /* eslint-disable no-await-in-loop */
      for (let k = 0; k < exportedData.rows.length; k += 1) {
        data.Row = exportedData.rows[k];
        this.currentCar += 1;
        const res = await ExternalLeasingCompaniesService.postVehicle(data[k], {
          headers: {
            ApiKey: this.apiKey,
            ApiSecret: this.secretKey,
          },
          ignoreAuthHandling: true,
        });
        if (res) {
          res.data.Success ? succeededRow += 1 : notSucceededRow += 1;
          this.results.rows.push({
            data: [
              res.data.RelatedValues.PlateNumber || data[k].PlateNumber,
              res.data.RelatedValues.ClientName || data[k].ClientName,
              res.data.RelatedValues.ClientIdNumber ||
              data[k].ClientIdNumber,
              res.data.RelatedValues.ContractStartDate ||
              data[k].ContractStartDate,
              res.data.RelatedValues.ContractEndDate ||
              data[k].ContractEndDate,
              res.data.RelatedValues.VehicleReturnDate ||
              data[k].VehicleReturnDate,
              res.data.RelatedValues.OwnershipStartDate ||
              data[k].OwnershipStartDate,
              res.data.RelatedValues.OwnershipEndDate ||
              data[k].OwnershipEndDate,
              res.data.RelatedValues.ClientCity || data[k].ClientCity,
              res.data.RelatedValues.ClientStreet || data[k].ClientStreet,
              res.data.RelatedValues.ClientZipCode || data[k].ClientZipCode,
              res.data.RelatedValues.ClientHouseNumber ||
              data[k].ClientHouseNumber,
              data[k].VehicleType,
              res.data.RelatedValues.Model || data[k].Model,
              res.data.RelatedValues.IsSellContract ||
              data[k].IsSellContract,
              res.data.Message,
            ],
            Succeeded: res.data.Success,
          });
        }
      }
        this.succeeded = succeededRow;
        this.notSucceeded = notSucceededRow;
      this.isLoading = false;
      this.$buefy.dialog.alert({
        title: 'הפעולה הסתיימה בהצלחה',
        message: `דוחות שהתקבלו: ${this.succeeded} <br>
               דוחות שלא התקבלו: ${this.notSucceeded}`,
        type: 'is-success',
        html: true,
        icon: 'times-circle',
        iconPack: 'fa',
        confirmText: 'אוקיי',
      });
      // data.forEach((reqObj) => {
      //   ExternalLeasingCompaniesService.postVehicle(reqObj, {
      //     headers: {
      //       ApiKey: this.apiKey,
      //       ApiSecret: this.secretKey,
      //     },
      //     ignoreAuthHandling: true,
      //   })
      //     .then((response) => {
      //       this.isResetHeaders = true;
      //       const mappedHeaders = headers.map((i) => i.Name);
      //       this.results.headers = mappedHeaders;
      //       mappedHeaders.push("Error");
      //       this.results.rows.push({
      //         data: [
      //           response.data.RelatedValues.PlateNumber || reqObj.PlateNumber,
      //           response.data.RelatedValues.ClientName || reqObj.ClientName,
      //           response.data.RelatedValues.ClientIdNumber ||
      //             reqObj.ClientIdNumber,
      //           response.data.RelatedValues.ContractStartDate ||
      //             reqObj.ContractStartDate,
      //           response.data.RelatedValues.ContractEndDate ||
      //             reqObj.ContractEndDate,
      //           response.data.RelatedValues.VehicleReturnDate ||
      //             reqObj.VehicleReturnDate,
      //           response.data.RelatedValues.OwnershipStartDate ||
      //             reqObj.OwnershipStartDate,
      //           response.data.RelatedValues.OwnershipEndDate ||
      //             reqObj.OwnershipEndDate,
      //           response.data.RelatedValues.ClientCity || reqObj.ClientCity,
      //           response.data.RelatedValues.ClientStreet || reqObj.ClientStreet,
      //           response.data.RelatedValues.ClientZipCode || reqObj.ClientZipCode,
      //           response.data.RelatedValues.ClientHouseNumber ||
      //             reqObj.ClientHouseNumber,
      //           reqObj.VehicleType,
      //           response.data.RelatedValues.Model || reqObj.Model,
      //           response.data.RelatedValues.IsSellContract ||
      //             reqObj.IsSellContract,
      //           response.data.Message,
      //         ],
      //         Succeeded: response.data.Success,
      //       });
      //     })
      //     .catch((err) => {
      //       this.$buefy.dialog.alert({
      //         title: "שימו לב!",
      //         message:
      //           err.response?.status === 401
      //             ? "לא ניתן להמשיך עם פרטי הזיהוי הנוכחים"
      //             : "קרתה תקלה לא ניתן לבצע את הפעולה",
      //         type: "is-danger",
      //         hasIcon: true,
      //         icon: "times-circle",
      //         iconPack: "fa",
      //         confirmText: "אוקיי",
      //       });
      //     });
      // });
    },
    step2() {
      if (this.$refs.table?.isValid()) {
        this.activeStep += 1;
      } else {
        this.$buefy.dialog.alert({
          title: "שימו לב!",
          message: "יש להשלים את כל השדות הדרושים על מנת להמשיך בתהליך",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          confirmText: "אוקיי",
        });
      }
    },
    async step1() {
      this.activeStep += 1;
      this.randomProgress();
      this.data = await this.uploadFile();
      if (!this.data) {
        this.onPrevious();
      } else {
        this.headerFields = await this.getReportFields();
        this.headers = this.createHeaders(this.table);
        this.activeStep += 1;
        this.progress = 100;
        clearInterval(this.randomProgressRef);
      }
    },
    createHeaders(table) {
      const length = this.getMaxHeadersLength(table);
      return Array.from({ length }, () => this.headerFields);
    },
    async getReportFields() {
      return headers;
    },
    getMaxHeadersLength(table) {
      let maxLenth = 0;
      table.forEach((item) => {
        maxLenth = Math.max(item.length, maxLenth);
      });
      return maxLenth;
    },
    onPrevious() {
      if (!this.activeStep) return;
      this.activeStep -= 1;
    },
    randomProgress() {
      this.progress = 0;
      this.randomProgressRef = setInterval(() => {
        this.progress += 5;
        this.progress = Math.min(100, this.progress);
      }, 200);
    },
    uploadFile() {
      const formData = new FormData();
      formData.append("file", this.file);
      return FilesService.getData(formData).catch(() => {
        this.$buefy.dialog.alert({
          title: "שימו לב!",
          message: "קרתה תקלה לא ניתן לבצע את הפעולה",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          confirmText: "אוקיי",
        });
      });
    },
  },
};
</script>
<style scoped lang="scss">
.Reports__Import__View h1 {
  font-size: 25px;
  padding: 25px 25px 0 0;
}

.Reports__Import__View__Steps {
  padding: 50px 20px 20px 20px;
  display: flex;
  align-content: center;

  ::v-deep .step-content {
    padding: 60px 0 10px 0;
  }

  section {
    width: 100%;
  }

  .ChooseFile__Step {
    display: flex;
    flex-direction: column;
    justify-items: center;
    padding: 30px 0;
    width: 600px;
    margin: 0 auto;

    ::v-deep {

      .field,
      .file {
        text-align: center;
        justify-content: center;
      }
    }
  }

  .Progress__Step {
    width: 600px;
    padding: 30px 0;
    margin: 0 auto;
  }

  ::v-deep {

    .b-steps .steps .step-items .step-item:not(:first-child)::before,
    .b-steps .steps .step-items .step-item:only-child::before {
      right: -50%;
      left: unset;
    }

    .b-steps .steps .step-items .step-item::before,
    .b-steps .steps .step-items .step-item::after {
      background-position: left bottom;
      background: linear-gradient(to right, #dbdbdb 50%, #7957d5 50%);
      background-size: 200% 100%;
    }

    .b-steps .steps .step-items .step-item.is-active::before,
    .b-steps .steps .step-items .step-item.is-active::after {
      background-position: right bottom;
    }

    .b-steps .steps .step-items .step-item.is-previous::before,
    .b-steps .steps .step-items .step-item.is-previous::after {
      background-position: right bottom;
    }
  }

  .TollRoads__Import__View__Steps__Navigations {
    display: flex;
    justify-content: center;

    ::v-deep button {
      margin: 0 5px;
    }
  }
}

::v-deep {
  .top.level {
    flex-direction: row-reverse;
  }

  .pagination.is-simple {
    flex-direction: row-reverse;
  }

  .b-table .table-wrapper.has-sticky-header {
    height: calc(100vh - 485px);
    overflow-y: auto;
  }
}

.starting-row {
  width: 300px;
  margin-bottom: 10px;
  display: flex;
  align-items: self-end;

  .field:not(:last-child) {
    margin-bottom: 0;
  }
}

.switch {
  direction: ltr;
}

.settings-step {
  width: 500px;
}

.importing-step {
  height: 500px;
}

.status-step {
  width: calc(100vw - 340px);
  overflow-x: auto;
  overflow-y: auto;
  height: calc(100vh - 500px);

  .table {
    margin-top: 30px;
    table-layout: fixed;
    overflow-x: auto;
    width: 100%;

    td,
    th {
      width: 200px;
    }

    tbody tr {
      background-color: #daffda;
    }
  }

  .has-error {
    background-color: #ffdada !important;
  }
}

::v-deep .table.is-hoverable tbody tr:not(.is-selected):hover {
  background-color: #eaf0f6 !important;
  cursor: pointer;
}

::v-deep .table.is-striped tbody tr:not(.is-selected):nth-child(even) {
  background-color: #efefef;
}
</style>
