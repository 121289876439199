<template>
  <div class="Admin__View">
    <div class="Admin__View__Profiles">
      <div class="is-flex is-justify-content-space-between is-align-items-center">
        <div class="is-flex">
          <div class="Field">
            <b-field label="חפש">
              <b-input v-model="term"></b-input>
            </b-field>
          </div>
         <b-field label="הצג פרופילים מנוהלים">
          <b-switch v-model="isManaged" class="ltr pt-2">
            {{isManaged ? 'כן': 'לא'}}
          </b-switch>
        </b-field>
       </div>
       <b-button type="is-primary" @click="addProfile">הוספת פרופיל</b-button>
      </div>
      <div class="Admin__View__Profiles__Table">
           <b-table
            paginated
            :sticky-header="true"
            :data="data"
            :pagination-simple="true"
            :hoverable="true"
            :loading="isLoading"
            per-page="30"
            default-sort-direction="asc"
            default-sort="Id"
            :mobile-cards="true">

              <b-table-column field="Id" label="מזהה" v-slot="props" centered sortable >
                {{ props.row.Id }}
              </b-table-column>

              <b-table-column label="ח.פ/ת.ז" v-slot="props" centered >
                {{ props.row.IdNumber }}
              </b-table-column>

              <b-table-column field="Name" label="שם" v-slot="props" sortable>
                {{ props.row.Name }}
              </b-table-column>

              <b-table-column label="כתובת" v-slot="props">
                {{ getAddress(props.row) }}
              </b-table-column>

              <b-table-column label="מיקוד" v-slot="props">
                {{ props.row.ZipCode }}
              </b-table-column>

              <b-table-column field="LastCheckDate" cellClass="table-date" label="נבדק לאחרונה" sortable centered v-slot="props">
                {{ parseDate(props.row.LastCheckDate) }}
              </b-table-column>

              <b-table-column field="IsLeasingCompany" label="חברת ליסינג" v-slot="props"
                 centered sortable>
                {{ props.row.IsLeasingCompany ? 'כן' : 'לא' }}
              </b-table-column>

              <b-table-column field="Status" label="סטטוס" v-slot="props"
                 centered sortable width="150">
                <div :class="`status status-${props.row.Status}`">
                  {{ getStatus(props.row.Status) }}
                </div>
              </b-table-column>

              <b-table-column field="Login" label="התחברות" centered v-slot="props">
                <div class="login">
                  <b-button type="is-primary" @click="onRowClick(props.row, true)">אדמין</b-button>
                  <b-button type="is-primary" @click="onRowClick(props.row, false)">לקוח</b-button>
                </div>
              </b-table-column>

            </b-table>
      </div>
    </div>
  </div>
</template>
<script>
import ProfileService from '@/services/ProfileService';
import DialogProfileAdd from '@/components/DialogProfileAdd.vue';
import { mapMutations } from 'vuex';
import moment from 'moment';

export default {
  name: 'Admin',
  data() {
    return {
      isLoading: false,
      isManaged: true,
      data: [],
      origData: [],
      term: null,
    };
  },
  components: {

  },
  created() {
    this.search();
  },
  methods: {
    ...mapMutations('auth', ['setProfileId']),
    addProfile() {
      this.$buefy.modal.open({
          component: DialogProfileAdd,
          canCancel: false,
          hasModalCard: true,
          trapFocus: true,
          props: {
            onSuccess: this.search,
          },
      });
    },
    onRowClick(row, isAdmin) {
      this.setProfileId(row.Id);
      if (!isAdmin) {
        localStorage.setItem('customer', true);
      }
      window.location.href = this.$router.resolve({ name: 'Reports' }).href;
    },
    parseDate(date) {
      return date ? moment(date).format("DD/MM/YYYY HH:mm") : '';
    },
    async search() {
      this.isLoading = true;
      const { data: profiles } = await ProfileService.all(this.isManaged);
      this.data = profiles;
      this.origData = [...profiles];
      this.isLoading = false;
    },
    getStatus(status) {
      switch (status) {
        case 1:
          return 'ממתין להרשמה';
        case 2:
          return 'ממתין לאישור';
        case 3:
          return 'פעיל';
        case 4:
          return 'משתמש חדש ממתין';
        default:
            return '';
      }
    },
    getAddress(row) {
      if (!row.City && !row.Street && !row.HouseNumber) return '';
      return `${row.City}, ${row.Street} ${row.HouseNumber}`;
    },
    filterByTerm(value = this.term) {
      this.data = this.origData
      .filter((i) => {
        const values = Object.values(i);
        return values.some((v) => (v
            && typeof v !== 'boolean'
            && typeof v !== 'number' ? !value || v.indexOf(value) > -1 : v === parseInt(value, 10)));
      });
    },
  },
  watch: {
    term(newValue) {
      this.filterByTerm(newValue);
    },
    async isManaged() {
      await this.search();
      this.filterByTerm();
    },
  },
};
</script>
<style scoped lang="scss">
  .Admin__View {
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
    height: 100%;

    .Admin__View__Profiles {
      height: 100%;

      .Field {
        padding: 0 15px 10px 15px;
        width: 300px;
      }

      .Admin__View__Profiles__Table {
        height: calc(100% - 130px);

        ::v-deep .table-date {
          direction: ltr;
          text-align: end;
        }

        ::v-deep  {
          .b-table  {
              height: 100%;
            .table-wrapper {
              min-height: 100%;
              overflow-y: auto;

              .status {
                border: 1px solid silver;
                border-radius: 3px;
                padding: 3px 0px;
                font-weight: 500;

                &.status-2 {
                  border-color: #159bb1;
                  color: #159bb1;
                }

                &.status-3 {
                  border-color: #009e07;
                  color: #009e07;
                }
              }
            }
            .top.level {
                justify-content: start;
                padding: 0 10px;

                .pagination.is-simple {
                    flex-direction: row-reverse;
                }
            }
          }
        }
      }
    }
  }
</style>
