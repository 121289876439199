import { render, staticRenderFns } from "./AdminAlertGenerator.vue?vue&type=template&id=355af8bc&scoped=true&"
import script from "./AdminAlertGenerator.vue?vue&type=script&lang=js&"
export * from "./AdminAlertGenerator.vue?vue&type=script&lang=js&"
import style0 from "./AdminAlertGenerator.vue?vue&type=style&index=0&id=355af8bc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "355af8bc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Mobaxe\\Betterway.vms\\Betterway.VMS.web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('355af8bc')) {
      api.createRecord('355af8bc', component.options)
    } else {
      api.reload('355af8bc', component.options)
    }
    module.hot.accept("./AdminAlertGenerator.vue?vue&type=template&id=355af8bc&scoped=true&", function () {
      api.rerender('355af8bc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/AdminAlertGenerator.vue"
export default component.exports