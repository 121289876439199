<template>
  <div class="Reports__Import__View">
    <h1>ייבוא דוחות</h1>
    <div class="Reports__Import__View__Steps">
      <section>
        <b-steps v-model="activeStep">
          <b-step-item :label="'בחירת קובץ'">
            <div class="ChooseFile__Step">
              <b-field label="בחר קובץ לייבוא">
                <b-field class="file is-primary" :class="{ 'has-name': !!file }">
                  <b-upload v-model="file" class="file-label" rounded>
                    <span class="file-cta">
                      <b-icon class="file-icon" icon="upload"></b-icon>
                      <span class="file-label">בחר קובץ...</span>
                    </span>
                    <span class="file-name" v-if="file">
                      {{ file.name }}
                    </span>
                  </b-upload>
                  <a href="https://api.betterway.co.il/ImportSamples/תבנית ייבוא דוחות.xlsx" target="_blank" style="margin-right: 10px;">
                    <b-button>
                      קובץ דוגמא
                    </b-button>
                  </a>
                </b-field>
              </b-field>
            </div>
          </b-step-item>
          <b-step-item :clickable="false" :label="'הבאת מידע'">
            <div class="Progress__Step">
              <b-progress type="is-primary" :value="progress" show-value format="percent"></b-progress>
            </div>
          </b-step-item>
          <b-step-item :label="'מיפוי שדות'">
            <div class="starting-row">
              <b-field label="ייבא נתונים החל משורה">
                <b-numberinput min="0" v-model="startingRow"></b-numberinput>
              </b-field>
              <!-- <b-tooltip label='זיהוי חכם מנסה לזהות את שדות החובה ע"פ שם הכותרת. במידה ואין כותרות, לא יתבצע זיהוי.'
                  position="is-left">
                  <b-button @click="smartAnalyze" class="mr-3" type="is-primary">בצע זיהוי חכם</b-button>
                </b-tooltip> -->
            </div>
            <div class="table-scrollable">
              <table-selectable ref="table" v-if="headers.length" :show-row-number="true" :starting-row="startingRow"
                :headers="headers" :data="table" />
            </div>
          </b-step-item>
          <b-step-item :label="'הגדרות'">
            <div class="settings-step">
              <b-field label="הערה">
                <b-input v-model="comment"></b-input>
              </b-field>
              <b-field label="תאריך קובע">
                <b-datepicker v-model="date" class="datetime" placeholder="לחץ כדי לבחור" icon-prev="chevron-right"
                  icon-next="chevron-left" icon="calendar-today" locale="en-GB"
                  :day-names="['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש']" :month-names="[
                    'ינואר',
                    'פברואר',
                    'מרץ',
                    'אפריל',
                    'מאי',
                    'יוני',
                    'יולי',
                    'אוגוסט',
                    'ספטמבר',
                    'אוקטובר',
                    'נובמבר',
                    'דצמבר',
                  ]" close-on-click editable append-to-body>
                </b-datepicker>
              </b-field>
              <b-field label="ייבוא ראשוני">
                <b-switch v-model="isFirstTime">
                  {{ isFirstTime ? "כן" : "לא" }}
                </b-switch>
              </b-field>
              <b-field label="בקש תצלום מסך לדוח">
                <b-switch v-model="getScreenShot">
                  {{ getScreenShot ? "כן" : "לא" }}
                </b-switch>
              </b-field>
            </div>
          </b-step-item>
          <b-step-item :label="'בחירת פרופיל'">
            <div class="is-flex">
              <div class="Field">
                <b-field label="חפש">
                  <b-input v-model="profileTerm"></b-input>
                </b-field>
              </div>
              <div class="Field">
                <b-field label="הצג פרופילים מנוהלים">
                  <b-switch v-model="isManaged" class="ltr pt-2">
                    {{ isManaged ? "כן" : "לא" }}
                  </b-switch>
                </b-field>
              </div>
            </div>
            <b-table paginated :sticky-header="true" :striped="true" :data="users" :pagination-simple="true"
              :selected.sync="selectedProfile" :hoverable="true" per-page="30" :loading="isLoadingProfiles"
              default-sort-direction="asc" default-sort="Id" @click="onProfileClick" :mobile-cards="true">
              <b-table-column field="Id" label="מזהה" v-slot="props" centered sortable>
                {{ props.row.Id }}
              </b-table-column>

              <b-table-column label="ח.פ/ת.ז" v-slot="props" centered>
                {{ props.row.IdNumber }}
              </b-table-column>

              <b-table-column field="Name" label="שם" v-slot="props" sortable>
                {{ props.row.Name }}
              </b-table-column>

              <b-table-column label="כתובת" v-slot="props">
                {{ getAddress(props.row) }}
              </b-table-column>

              <b-table-column label="מיקוד" v-slot="props">
                {{ props.row.ZipCode }}
              </b-table-column>

              <b-table-column field="IsLeasingCompany" label="חברת ליסינג" v-slot="props" centered sortable>
                {{ props.row.IsLeasingCompany ? "כן" : "לא" }}
              </b-table-column>
            </b-table>
          </b-step-item>
          <b-step-item :label="'מייבא נתונים'" :visible="isImporting">
            <div class="importing-step">
              <b-loading :is-full-page="false" v-model="isImporting" :can-cancel="false"></b-loading>
            </div>
          </b-step-item>
          <b-step-item class="table-item" :label="'סטטוס'">
            <div class="status-step">
              <div class="results-reports">
                <div style="position: relative; display: flex; align-items: center; gap: 10px">
                  <div v-if="isLoading">דוח: {{ `${totalReports} / ${currentReport}` }}</div>
                  <b-icon v-if="isLoading" pack="fas" icon="sync-alt" custom-class="fa-spin">
                  </b-icon>
                </div>
                <p v-if="this.succeeded">דוחות שהתקבלו: {{ succeeded }}</p>
                <p v-if="this.notSucceeded">דוחות שלא התקבלו: {{ notSucceeded }}</p>
              </div>
              <div class="is-flex is-justify-content-flex-end">
                <b-tooltip label="ייצוא לאקסל">
                  <b-button @click="exportToExcel">
                    <b-icon icon="file-excel-outline"> </b-icon>
                  </b-button>
                </b-tooltip>
              </div>
              <table v-if="!hideResults" class="table">
                <thead>
                  <tr>
                    <th v-for="(header, index) in results.headers" :key="index">
                      {{ header }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr :class="{ 'has-error': !row.Succeeded }" v-for="(row, rowIndex) in results.rows" :key="rowIndex">
                    <td v-for="(value, valueIndex) in row.data" :key="valueIndex">
                      {{ value }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-step-item>

          <template #navigation="{ previous, next }">
            <div class="TollRoads__Import__View__Steps__Navigations" v-show="activeStep !== 6">
              <b-button outlined type="is-primary" icon="" @click.prevent="onPrevious(previous)">
                חזור
              </b-button>
              <b-button outlined type="is-primary" icon="" @click.prevent="onNext(next)">
                המשך
              </b-button>
            </div>
          </template>
          <template v-if="activeStep === 6">
            <div class="mt-5">
              <b-button type="is-primary" @click="reset">התחל ייבוא חדש</b-button>
            </div>
          </template>
        </b-steps>
      </section>
    </div>
  </div>
</template>
<script>
import FilesService from "@/services/FilesService";
import TableSelectable from "@/components/TableSelectable.vue";
import ReportService from "@/services/ReportService";
import { ToastProgrammatic as Toast } from 'buefy';
import ProfileService from "@/services/ProfileService";
import { saveAs } from "file-saver";
import moment from "moment";

export default {
  components: { TableSelectable },
  name: "AdminImportReports",
  data() {
    return {
      isImporting: false,
      profileTerm: null,
      activeStep: 0,
      file: null,
      randomProgressRef: null,
      progress: 0,
      data: [],
      results: {
        headers: [],
        rows: [],
      },
      succeeded: 0,
      notSucceeded: 0,
      selectedProfile: null,
      isLoadingProfiles: false,
      currentReport: 0,
      isLoading: false,
      totalReports: 0,
      hideResults: false,
      isManaged: true,
      users: [],
      isFirstTime: false,
      date: null,
      getScreenShot: false,
      comment: null,
      startingRow: 1,
      headers: [],
      currentPage: 1,
      perPage: 30,
      headerFields: [],
    };
  },
  computed: {
    table() {
      return this.data?.data;
    },
    excelMimeType() {
      return "application/vnd.ms-excel";
    },
  },
  async created() {
    await this.searchUsers();
  },
  methods: {
    async exportToExcel() {
      let data = [];
      data.push(this.results.headers);
      data = data.concat(this.results.rows.map((i) => i.data));
      const { data: r } = await FilesService.postData(data);
      const blob = new Blob([r], { type: this.excelMimeType });
      saveAs(blob, "ייבוא דוחות.xlsx");
    },
    reset() {
      this.activeStep = 0;
      this.progress = 0;
      this.file = null;
      this.isFirstTime = false;
      this.date = null;
      this.data = [];
      this.startingRow = 1;
      this.currentPage = 1;
      this.perPage = 30;
      this.headers = [];
      this.getScreenShot = false;
      this.comment = null;
      this.selectedProfile = null;
      this.profileTerm = null;
      this.results = {
        headers: [],
        data: [],
      };
    },
    async searchUsers() {
      this.isLoadingProfiles = true;
      const { data: profiles } = await ProfileService.all(this.isManaged);
      this.users = profiles;
      this.origData = [...this.users];
      this.isLoadingProfiles = false;
    },
    onProfileClick(row) {
      this.selectedProfile = row;
    },
    smartAnalyze() {
      this.$buefy.dialog.confirm({
        title: "זיהוי חכם",
        message:
          "שים לב, המערכת תבצע עבורך זיהוי חכם. במידה ויימצא שדה מתאים היא תדרוס את הבחירה הקיימת. האם אתה בטוח שברצונך להמשיך?",
        confirmText: "כן",
        cancelText: "לא",
        onConfirm: () => this.$refs.table.analyze(),
      });
    },
    async onNext() {
      if (this.activeStep === 0 && this.file) {
        await this.step1();
        return;
      }
      if (this.activeStep === 2) {
        this.step2();
        return;
      }
      if (this.activeStep === 3) {
        this.activeStep += 1;
        return;
      }
      if (this.activeStep === 4) {
        this.step5();
      }
    },
    getAddress(row) {
      if (!row.City && !row.Street && !row.HouseNumber) return "";
      return `${row.City}, ${row.Street} ${row.HouseNumber}`;
    },
    toDateFormat: (value, format) => {
      let date;
      if (!value) return value;
      if (typeof value !== "object" && (!value || !value.length)) return value;
      if (!format && value.length === 10 && /\d{4}-\d{2}-\d{2}/.test(value)) {
        return value;
      }
      // eslint-disable-next-line no-param-reassign
      if (!format) format = "YYYY-MM-DD";
      if (typeof value === "object") {
        date = moment(value);
      } else if (value.indexOf("T") > -1 || value.substr(4, 1) === "-") {
        date = moment(value, "YYYY-MM-DD");
      } else {
        date = moment(value, "DD/MM/YYYY");
      }
      return date.format(format);
    },
    async step5() {
      if (!this.selectedProfile) {
        return;
      }
      this.isImporting = true;
      this.activeStep += 1;
      const exportedData = this.$refs.table.export();
      const data = {
        VerificationDate: this.toDateFormat(this.date),
        IsSetup: this.isFirstTime,
        Columns: exportedData.columns,
        // OriginalRows: exportedData.originalRows,
        // Rows: exportedData.rows,
        GetScreenshots: this.getScreenShot,
        ProfileId: this.selectedProfile.Id,
        Comment: this.comment,
        FileName: this.file.name,
      };

      this.totalReports = exportedData.rows.length;

      if (exportedData.rows.length > 1000) {
        this.activeStep += 1;
        this.isLoading = true;
        this.hideResults = true;

        /* eslint-disable no-await-in-loop */
        let succeededRow = 0;
        let notSucceededRow = 0;
        const maxRetries = 1;
        let retry = 0;
        for (let i = 0; i < exportedData.rows.length; i += 1) {
          data.Row = exportedData.rows[i];
          this.currentReport += 1;
          const res = await ReportService.importSingleReport(data);
          if (res.status === 502) {
            if (retry === maxRetries) return;
            retry += 1;
            Toast.open({
              type: 'is-warning',
              message: 'הבקשה נכשלה, מנסים שוב בעוד 5 שניות...',
              position: 'is-bottom',
              duration: 5000
            });
            i -= 1;
            await new Promise((resolve) => setTimeout(resolve, 5000));
          } else if (res) {
            const singleResult = this.buildResults(exportedData, { Rows: [res.data] });
            this.results.rows.push(singleResult.rows[0]);
            res.data.Succeeded ? succeededRow += 1 : notSucceededRow += 1;
          }
        }
        this.succeeded = succeededRow;
        this.notSucceeded = notSucceededRow;
        this.isLoading = false;
        this.$buefy.dialog.alert({
          title: 'הפעולה הסתיימה בהצלחה',
          message: `דוחות שהתקבלו: ${this.succeeded} <br>
               דוחות שלא התקבלו: ${this.notSucceeded}`,
          type: 'is-success',
          html: true,
          icon: 'times-circle',
          iconPack: 'fa',
          confirmText: 'אוקיי',
        });
      } else {
        data.OriginalRows = exportedData.originalRows;
        data.Rows = exportedData.rows;
        this.isLoading = true;
        const { data: uuid } = await ReportService.importReports(data);
        const importTimer = setInterval(() => {
          ReportService.importReportsStatus(uuid).then((r) => {
            this.results = this.buildResults(exportedData, r.data);
            this.activeStep += 1;
            this.succeeded = r.data.Rows.filter((row) => row.Succeeded).length;
            this.notSucceeded = r.data.Rows.filter((row) => !row.Succeeded).length;
            this.$buefy.dialog.alert({
              title: 'הפעולה הסתיימה בהצלחה',
              message: `דוחות שהתקבלו: ${this.succeeded} <br>
               דוחות שלא התקבלו: ${this.notSucceeded}`,
              type: 'is-success',
              html: true,
              icon: 'times-circle',
              iconPack: 'fa',
              confirmText: 'אוקיי',
            });
            this.isLoading = false;
            clearInterval(importTimer);
          });
        }, 2000);
      }
    },
    step2() {
      if (this.$refs.table?.isValid()) {
        this.activeStep += 1;
      } else {
        this.$buefy.dialog.alert({
          title: "שימו לב!",
          message: "יש להשלים את כל השדות הדרושים על מנת להמשיך בתהליך",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          confirmText: "אוקיי",
        });
      }
    },
    async step1() {
      this.activeStep += 1;
      this.randomProgress();
      this.data = await this.uploadFile();
      if (!this.data) {
        this.onPrevious();
      } else {
        this.headerFields = await this.getReportFields();
        this.headers = this.createHeaders(this.table);
        this.activeStep += 1;
        this.progress = 100;
        clearInterval(this.randomProgressRef);
      }
    },
    buildResults(exportedData, response) {
      const { columns: headers } = exportedData;
      const results = {
        headers: headers.concat("Error1", "Error2", "Error3"),
        rows: [],
      };
      const rows = [...response.Rows];
      rows.forEach((r) => {
        const row = {};
        row.Succeeded = r.Succeeded;
        row.data = r.Data.concat(r.Errors);
        results.rows.push(row);
      });
      return results;
    },
    createHeaders(table) {
      const length = this.getMaxHeadersLength(table);
      return Array.from({ length }, () => this.headerFields);
    },
    async getReportFields() {
      const { data } = await ReportService.getFields();
      return data;
    },
    getMaxHeadersLength(table) {
      let maxLenth = 0;
      table.forEach((item) => {
        maxLenth = Math.max(item.length, maxLenth);
      });
      return maxLenth;
    },
    onPrevious() {
      if (!this.activeStep) return;
      this.activeStep -= 1;
    },
    randomProgress() {
      this.progress = 0;
      this.randomProgressRef = setInterval(() => {
        this.progress += 5;
        this.progress = Math.min(100, this.progress);
      }, 200);
    },
    uploadFile() {
      const formData = new FormData();
      formData.append("file", this.file);
      return FilesService.getData(formData).catch(() => {
        this.$buefy.dialog.alert({
          title: "שימו לב!",
          message: "קרתה תקלה לא ניתן לבצע את הפעולה",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          confirmText: "אוקיי",
        });
      });
    },
    filterByTerm(value = this.term) {
      this.users = this.origData.filter((i) => {
        const values = Object.values(i);
        return values.some((v) =>
        (v && typeof v !== "boolean" && typeof v !== "number"
          ? !value || v.indexOf(value) > -1
          : v === parseInt(value, 10))
        );
      });
    },
  },
  watch: {
    profileTerm(newValue) {
      this.filterByTerm(newValue);
    },
    async isManaged() {
      await this.searchUsers();
      this.filterByTerm();
    },
  },
};
</script>
<style scoped lang="scss">
.Reports__Import__View h1 {
  font-size: 25px;
  padding: 25px 25px 0 0;
}

// .table tbody {
//   height: calc(100vh - 485px);
//   overflow-y: auto;
// }

tbody {
  display: block;
  height: 250px;
  overflow: auto;
}

thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  /* even columns width , fix width of table too*/
}

thead {
  width: calc(100% - 1em)
    /* scrollbar is average 1em/16px width, remove it from thead width */
}

.Reports__Import__View__Steps {
  padding: 50px 20px 20px 20px;
  display: flex;
  align-content: center;

  ::v-deep .step-content {
    padding: 60px 0 10px 0;
  }

  section {
    width: 100%;
  }

  .ChooseFile__Step {
    display: flex;
    flex-direction: column;
    justify-items: center;
    padding: 30px 0;
    width: 600px;
    margin: 0 auto;

    ::v-deep {

      .field,
      .file {
        text-align: center;
        justify-content: center;
      }
    }
  }

  .Progress__Step {
    width: 600px;
    padding: 30px 0;
    margin: 0 auto;
  }

  ::v-deep {

    .b-steps .steps .step-items .step-item:not(:first-child)::before,
    .b-steps .steps .step-items .step-item:only-child::before {
      right: -50%;
      left: unset;
    }

    .b-steps .steps .step-items .step-item::before,
    .b-steps .steps .step-items .step-item::after {
      background-position: left bottom;
      background: linear-gradient(to right, #dbdbdb 50%, #7957d5 50%);
      background-size: 200% 100%;
    }

    .b-steps .steps .step-items .step-item.is-active::before,
    .b-steps .steps .step-items .step-item.is-active::after {
      background-position: right bottom;
    }

    .b-steps .steps .step-items .step-item.is-previous::before,
    .b-steps .steps .step-items .step-item.is-previous::after {
      background-position: right bottom;
    }
  }

  .TollRoads__Import__View__Steps__Navigations {
    display: flex;
    justify-content: center;

    ::v-deep button {
      margin: 0 5px;
    }
  }
}

.table-item {
  height: calc(100vh - 485px);
  overflow-y: auto;
}

::v-deep {
  .top.level {
    flex-direction: row-reverse;
  }

  .pagination.is-simple {
    flex-direction: row-reverse;
  }

  .b-table .table-wrapper.has-sticky-header {
    height: calc(100vh - 485px);
    overflow-y: auto;
  }
}

.starting-row {
  width: 300px;
  margin-bottom: 10px;
  display: flex;
  align-items: self-end;

  .field:not(:last-child) {
    margin-bottom: 0;
  }
}

.switch {
  direction: ltr;
}

.settings-step {
  width: 500px;
}

.importing-step {
  height: 500px;
}

.status-step {
  .table {
    width: 100%;
    margin-top: 30px;

    tbody tr {
      background-color: #daffda;
    }
  }

  .has-error {
    background-color: #ffdada !important;
  }
}

::v-deep .table.is-hoverable tbody tr:not(.is-selected):hover {
  background-color: #eaf0f6 !important;
  cursor: pointer;
}

::v-deep .table.is-striped tbody tr:not(.is-selected):nth-child(even) {
  background-color: #efefef;
}
</style>
