var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Profile__Page" }, [
    _c(
      "div",
      { staticClass: "Profile__Page__Menu" },
      [
        _c("h1", [_vm._v("אדמיניסטרציה")]),
        _c(
          "b-menu",
          [
            _c(
              "b-menu-list",
              [
                _c("b-menu-item", {
                  attrs: {
                    label: "פרופילים",
                    tag: "router-link",
                    to: "/admin/profiles"
                  }
                }),
                _c("b-menu-item", {
                  attrs: {
                    label: "ניהול דוחות",
                    tag: "router-link",
                    to: "/admin/search/reports"
                  }
                }),
                _c("b-menu-item", {
                  attrs: {
                    label: "דוחות לא מנוהלים",
                    tag: "router-link",
                    to: "/admin/search/unmanagedreports"
                  }
                }),
                _c("b-menu-item", {
                  attrs: {
                    label: "ניהול רכבים",
                    tag: "router-link",
                    to: "/admin/search/vehicles"
                  }
                }),
                _c("b-menu-item", {
                  attrs: {
                    label: "ניהול עיריות",
                    tag: "router-link",
                    to: "/admin/search/municipalities"
                  }
                }),
                _c(
                  "b-menu-item",
                  {
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function(props) {
                          return [
                            _vm._v(" ייבוא "),
                            _c("b-icon", {
                              staticClass: "is-pulled-left",
                              attrs: {
                                icon: props.expanded ? "menu-up" : "menu-down"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c("b-menu-item", {
                      attrs: {
                        label: "דוחות",
                        tag: "router-link",
                        to: "/admin/import/reports"
                      }
                    }),
                    _c("b-menu-item", {
                      attrs: {
                        label: "נהגים",
                        tag: "router-link",
                        to: "/admin/import/drivers"
                      }
                    }),
                    _c("b-menu-item", {
                      attrs: {
                        label: "רכבים",
                        tag: "router-link",
                        to: "/admin/import/vehicles"
                      }
                    }),
                    _c("b-menu-item", {
                      attrs: {
                        label: "רכבים עם בעלות בלבד",
                        tag: "router-link",
                        to: "/admin/import/vehicles/owned"
                      }
                    }),
                    _c("b-menu-item", {
                      attrs: {
                        label: "רכבים גרסה 2",
                        tag: "router-link",
                        to: "/admin/import/newvehicles"
                      }
                    }),
                    _c("b-menu-item", {
                      attrs: {
                        label: "משטרה",
                        tag: "router-link",
                        to: "/admin/import/police"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-menu-item",
                  {
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function(props) {
                          return [
                            _vm._v(" כלים "),
                            _c("b-icon", {
                              staticClass: "is-pulled-left",
                              attrs: {
                                icon: props.expanded ? "menu-up" : "menu-down"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c("b-menu-item", {
                      attrs: {
                        label: "יצירת קובץ לייבוא",
                        tag: "router-link",
                        to: "/admin/import/create"
                      }
                    }),
                    _c("b-menu-item", {
                      attrs: {
                        label: "סריקת נתוני מיקום",
                        tag: "router-link",
                        to: "/admin/import/locationdatascan"
                      }
                    }),
                    _c("b-menu-item", {
                      attrs: {
                        label: "סריקת נתוני דוחות",
                        tag: "router-link",
                        to: "/admin/crawlReports"
                      }
                    }),
                    _c("b-menu-item", {
                      attrs: {
                        label: "מחולל התראות",
                        tag: "router-link",
                        to: "/admin/import/generatealerts"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-menu-item",
                  {
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function(props) {
                          return [
                            _vm._v(" שידור דוחות "),
                            _c("b-icon", {
                              staticClass: "is-pulled-left",
                              attrs: {
                                icon: props.expanded ? "menu-up" : "menu-down"
                              }
                            }),
                            _c("b-icon", {
                              staticClass: "is-pulled-left",
                              class: {
                                "is-success": _vm.isHealthy,
                                "is-danger": _vm.isHealthy === false
                              },
                              attrs: { size: "is-small", icon: "circle" }
                            })
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c("b-menu-item", {
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "is-flex is-justify-content-space-evenly is-flex-direction-column"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "field" },
                                    [
                                      _c(
                                        "b-field",
                                        { attrs: { label: "בחר הרצה" } },
                                        [
                                          _c(
                                            "v-select",
                                            {
                                              attrs: {
                                                dir: "rtl",
                                                label: "Text",
                                                options: _vm.reportsOptions,
                                                searchable: false,
                                                clearable: false,
                                                "close-on-select": true,
                                                placeholder: "בחר ערך מהרשימה"
                                              },
                                              model: {
                                                value: _vm.reportsType,
                                                callback: function($$v) {
                                                  _vm.reportsType = $$v
                                                },
                                                expression: "reportsType"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "no-options" },
                                                  slot: "no-options"
                                                },
                                                [_vm._v("לא נמצאו ערכים דומים")]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.startTransmittion($event)
                                        }
                                      }
                                    },
                                    [_vm._v("הפעל")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.cancelTransmittion($event)
                                        }
                                      }
                                    },
                                    [_vm._v("הפסק")]
                                  )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "Profile__Page__Content" },
      [_vm.ready ? _c("router-view") : _vm._e()],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }