var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ActivityItemTask__Component" }, [
    _vm.isOpen
      ? _c("div", { staticClass: "ActivityItemTask__Component__Edit" }, [
          _c("div", { staticClass: "NewTask__Component__Attributes" }, [
            _c(
              "div",
              { staticClass: "Field ml-6" },
              [
                _c("FieldInlineText", {
                  attrs: {
                    "hide-hover": true,
                    label: "שם עיריה",
                    clearable: false,
                    filterable: false,
                    readOnly: true
                  },
                  on: { onChange: _vm.onChange },
                  model: {
                    value: _vm.model.Name,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "Name", $$v)
                    },
                    expression: "model.Name"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "Field ml-6" },
              [
                _c("FieldInlineText", {
                  attrs: {
                    "hide-hover": true,
                    label: "כתובת",
                    clearable: false,
                    filterable: false,
                    changeOnBlur: true
                  },
                  on: { onChange: _vm.onChange },
                  model: {
                    value: _vm.model.Address,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "Address", $$v)
                    },
                    expression: "model.Address"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "Field ml-6" },
              [
                _c("FieldInlineSelect", {
                  attrs: {
                    "hide-hover": true,
                    label: 'חתימת עו"ד',
                    clearable: false,
                    filterable: false,
                    options: [
                      { Value: true, Text: "צריך" },
                      { Value: false, Text: "לא צריך" }
                    ]
                  },
                  on: { onChange: _vm.onChange },
                  model: {
                    value: _vm.model.NeedLawyerSign,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "NeedLawyerSign", $$v)
                    },
                    expression: "model.NeedLawyerSign"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "Field ml-6" },
              [
                _c("FieldInlineSelect", {
                  attrs: {
                    "hide-hover": true,
                    label: ' חתימת עו"ד לנהג',
                    clearable: false,
                    filterable: false,
                    options: [
                      { Value: true, Text: "צריך" },
                      { Value: false, Text: "לא צריך" }
                    ]
                  },
                  on: { onChange: _vm.onChange },
                  model: {
                    value: _vm.model.NeedLawyerSignForDriver,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "NeedLawyerSignForDriver", $$v)
                    },
                    expression: "model.NeedLawyerSignForDriver"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "Field ml-6" },
              [
                _c("FieldInlineText", {
                  attrs: {
                    "hide-hover": true,
                    label: "אימייל להסבה",
                    clearable: false,
                    filterable: false,
                    changeOnBlur: true
                  },
                  on: { onChange: _vm.onChange },
                  model: {
                    value: _vm.model.TransferEmail,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "TransferEmail", $$v)
                    },
                    expression: "model.TransferEmail"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "Field ml-6" },
              [
                _c("FieldInlineText", {
                  attrs: {
                    "hide-hover": true,
                    label: "לינק לתשלום",
                    clearable: false,
                    filterable: false,
                    changeOnBlur: true
                  },
                  on: { onChange: _vm.onChange },
                  model: {
                    value: _vm.model.PaymentUrl,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "PaymentUrl", $$v)
                    },
                    expression: "model.PaymentUrl"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "Field ml-6" },
              [
                _c("FieldInlineSelect", {
                  attrs: {
                    "hide-hover": true,
                    label: "TransferBotId",
                    clearable: true,
                    filterable: false,
                    optionsAsync: _vm.getTransferBotIds
                  },
                  on: { onChange: _vm.onChange },
                  model: {
                    value: _vm.model.TransferBotId,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "TransferBotId", $$v)
                    },
                    expression: "model.TransferBotId"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "Field ml-6" },
              [
                _c("FieldInlineText", {
                  attrs: {
                    "hide-hover": true,
                    label: "TransferBotAuthorityId",
                    clearable: false,
                    filterable: false,
                    changeOnBlur: true
                  },
                  on: { onChange: _vm.onChange },
                  model: {
                    value: _vm.model.TransferBotAuthorityId,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "TransferBotAuthorityId", $$v)
                    },
                    expression: "model.TransferBotAuthorityId"
                  }
                })
              ],
              1
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }